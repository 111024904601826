@include bem-block(file-field) {

  @include bem-element(input) {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }

  @include bem-element(label) {
    background: transparent;
    border: 1px solid $alto;
    color: brand-color(xanadu-grey);
    cursor: pointer;
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    overflow: hidden;
    padding: 2em;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: unset;
    transition: all 0.2s ease;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  @include bem-element(label, $modifier: selected) {
    background: brand-color("ivory");
  }

  @include bem-element(progress) {
    background-color: map_get($color-list, 'success');
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0.4;
    position: absolute;
    top: 0;
    width: 0;
  }

  @include bem-element(error) {
    color: brand-color(brick-red);
    font-size: 1.4rem;
    line-height: 1.3;
    margin: 0.5em 0 1em;
  }
}

@include bem-block(file-field, $modifier: themed) {

  input[type="file"]:focus {

    & ~ label {
      border-color: #555;
    }
  }

  @include bem-element(label) {
    &:hover {
      @include theme-color(highlight);
    }
  }
  @include bem-element(label, $modifier: selected) {
    @include theme-background-color(secondary);
  }
}
