.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel],
.intl-tel-input.allow-dropdown input[type=text] {
  padding-right: 5px;
  padding-left: 65px;
  margin-left: 0;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 57px;
  padding-left: 18px;
}
