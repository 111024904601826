@include bem-block(careers-hero-block) {
  padding: 20px 20px 20px;

  // Prevent the first element from having any top margin if it's got a different background color
  + .careers-block.careers-block--theme-secondary {
    margin-top: 0;
  }

  @include breakpoint(small) {
    padding: 40px 30px 40px;
  }

  @include bem-element(logo-text) {
    font-size: 1.4rem;
    font-weight: 700;
  }

  @include bem-element(content) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  // If we don't have an image we wont have a min-height
  // This ensures there's enough spacing at the bottom if we're just using a background colour
  @include bem-elements((title, text)) {
    &:last-child {
      margin-bottom: 40px;

      @include breakpoint(small) {
        margin-bottom: 60px;
      }
    }
  }

  @include bem-element(title) {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.1;
    margin: 60px 0 20px 0;

    @include breakpoint(small) {
      font-size: 6rem;
      margin: 100px 0 30px 0;
    }
  }

  @include bem-element(text) {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;

    @include breakpoint(small) {
      font-size: 2.4rem;
    }
  }
}

@include bem-block(careers-hero-block, $modifier: image) {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;

  @include breakpoint(small) {
    min-height: 600px;
  }
}

@include bem-block(careers-hero-block, $modifier: video) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  min-height: 300px;

  @include breakpoint(small) {
    min-height: 600px;
  }
}

@include bem-block(careers-hero-block-container) {
  position: relative;
  overflow: hidden;
  z-index: 0;
  padding: 20px 20px 20px 20px;

  @include breakpoint(small) {
    padding: 40px 30px 40px;
  }
}

@include bem-block(careers-hero-iframe) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);


  @media (min-aspect-ratio: 16/9) {
    height: 56.25vw
  }

  @media (max-aspect-ratio: 16/9) {
    width: 177.78vh
  }
}

@include bem-block(careers-hero-block, $modifier: align-left) {
  @include bem-element(content-wrap) {
    @include breakpoint(small) {
      margin-left: 6%;
    }
  }
  @include bem-elements(content) {
    align-items: flex-start;
  }
  @include bem-elements((title, text)) {
    text-align: left;
  }
}


@include bem-block(careers-hero-block, $modifier: align-center) {
  @include bem-elements(content) {
    align-items: center;
  }
  @include bem-elements((title, text)) {
    text-align: center;
  }
}


@include bem-block(careers-hero-block, $modifier: align-right) {
  @include bem-element(content-wrap) {
    @include breakpoint(small) {
      margin-right: 6%;
    }
  }
  @include bem-elements(content) {
    align-items: flex-end;
  }
  @include bem-elements((title, text)) {
    text-align: right;
  }
}
