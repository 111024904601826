@include bem-block(careers-navigation-overflow-menu) {
  // this root element is the portal or react component
  max-width: 100%;

  @include bem-element(backdrop) {
    @include theme-background-color(primary);
  }
  
  @include bem-element(dialog) {
    @include theme-font-color(primary);
    background-color: transparent;
    box-shadow: none;
  }

  @include bem-element(menu-item) {
    font-weight: bolder;
    padding: 16px;
    white-space: nowrap;
  }

  @include bem-element(menu-section, $modifier: center) {
    min-width: 0;
  }
}
