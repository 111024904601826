$article-variables: (
  content-max-width: 768px,
  horizontal-padding-mobile: 16px,
  horizontal-padding-tablet: 48px,
);

@include bem-block(article) {
  padding: 0px;

  @include breakpoint(medium) {
    padding: 0px;
  }

  @include breakpoint(large) {
    padding: 0;
  }

  @include bem-element(image) {
    display: block;
    margin: 0;
    position: initial;
    width: 100%;
    left: 0;
    max-width: 100%;
  }

  @include bem-element(content) {

    @include breakpoint(small) {
      position: relative;
      top: -72px;
      margin-bottom: -72px; // To ensure content below is properly spaced
      margin-left: 32px;
      width: calc(#{map-get($article-variables, 'content-max-width')} - 32px * 2);
    }

    @include breakpoint(medium) {
      top: -96px;
      margin-left: 64px;
      margin-bottom: -96px;  // To ensure content below is properly spaced
      width: map-get($article-variables, 'content-max-width');
    }

    @include breakpoint(large) {
      top: -160px;
      margin-bottom: -160px;  // To ensure content below is properly spaced
    }
  }

  @include bem-element(header) {
    background: white;
  }

  @include bem-element(header-inner) {
    padding: map-get($article-variables, 'horizontal-padding-mobile');

    @include breakpoint(small) {
      padding: map-get($article-variables, 'horizontal-padding-tablet');
    }
  }

  @include bem-element(title) {
    @include header-font-alt;
    color: brand-color("charcoal");
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 20px;

    @include breakpoint(small) {
      font-size: 4rem;
      margin: 0 0 30px;
    }

    @include breakpoint(medium) {
      font-size: 5rem;
    }
  }

  @include bem-element(meta) {
    color: brand-color("slate-grey");
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 1px;

    @include breakpoint(small) {
      font-size: 1.8rem;
    }
  }

  @include bem-element(author) {
    color: brand-color("charcoal");
    font-weight: 500;
  }

  @include bem-element(text) {
    color: brand-color("charcoal");
    margin: 32px 0;
    padding: 0 map-get($article-variables, 'horizontal-padding-mobile');

    @include breakpoint(small) {
      margin: 48px 0;
      padding: 0 map-get($article-variables, 'horizontal-padding-tablet');
    }
  }

  a:not(.marketing-button):not(.marketing-author-card__name) {
    color: brand-color(brick-red);
    transition: all 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }

  aside {
    background-color: white;
    margin: 48px 16px;
    padding: 16px;

    @include breakpoint(small) {
      margin: 64px 16px;
      padding: 32px;
    }

    @include breakpoint(medium) {
      margin: 64px 24px;
      padding: 40px;
    }

    @include breakpoint(large) {
      left: map-get($article-variables, 'content-max-width');
      margin: 0;
      position: absolute;
      width: 320px;
    }

    header {
      color: brand-color("charcoal");
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 2px;
      margin: 0 0 1.25em 0;
      text-transform: uppercase;

      @include breakpoint(small) {
        font-size: 1.2rem;
        letter-spacing: 3px;
      }
    }

    p {
      color: brand-color(dark-peach);
      font-size: 1rem;
      line-height: 1.6;
      margin: 1.25em 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include breakpoint(small) {
        font-size: 1.6rem;
      }
    }
  }

  blockquote {
    color: brand-color(mustard-yellow);
    font-size: 2.4rem;
    line-height: 1.3;
    margin: 1.25em 0.5em;
    text-align: center;

    &:before {
      content: "\201C";
      position: relative;
      right: 0;
    }

    &:after {
      content: "\201D";
      position: relative;
      left: 0;
    }

    @include breakpoint(small) {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 2.3rem;
    font-weight: normal;
    line-height: 1.2;
    margin: 1.25em 0 0.5em 0;

    @include breakpoint(small) {
      font-size: 3.4rem;
      line-height: 1.6;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 1.6;
    margin: 2em 0 0.25em 0;
    text-transform: uppercase;

    @include breakpoint(small) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.6;
    margin: 2em 0 0.5em 0;
    text-transform: none;

    @include breakpoint(small) {
      font-size: 2rem;
    }
  }

  img {
    display: block;
    margin: 24px 0;
    position: relative;
    left: (map-get($article-variables, 'horizontal-padding-mobile'));
    width: calc(100% - #{map-get($article-variables, 'horizontal-padding-mobile') * 2});
    max-width: calc(100% - #{map-get($article-variables, 'horizontal-padding-mobile') * 2});

    @include breakpoint(small) {
      margin: 48px 0;
      left: -48px;
      width: calc(100% + 96px);
      max-width: calc(100% + 96px);
    }
  }

  @include bem-element(lead-in) {
    font-weight: 500;
    font-size: 1.8rem;
    @include breakpoint(small) {
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin: 0 0 1.75em 0;

    &:first-child {
      font-size: 2.0rem;
      font-weight: 500;
    }

    @include breakpoint(small) {
      font-size: 2rem;

      &:first-child {
        font-size: 3rem;
      }
    }
  }

  ol,
  ul {
    font-size: 1.6rem;
    margin: 0 0 2em 0;
    padding: 0 0 0 1em;

    @include breakpoint(small) {
      font-size: 2rem;
    }

    li {
      font-size: 1.6rem;
      line-height: 1.6;
      padding: 0 0 0 1.6em;
      margin-bottom: 1.25em;

      @include breakpoint(small) {
        font-size: 2rem;
      }
    }
  }

  ul {
    list-style-type: none;

    li {
      background-image: url('../../images/icons/two_arrows_sea_green.svg');
      background-repeat: no-repeat;
      background-position: 0 0.33em;
      background-size: 0.8em 0.8em;
    }
  }
}

@include bem-block(article, $modifier: no-image) {
  padding-top: 0;

  @include breakpoint(small) {
    padding-top: 54px;
  }
  @include breakpoint(medium) {
    padding-top: 96px;
  }
  @include breakpoint(large) {
    padding-top: 160px;
  }
}

@include bem-block(article, $modifier: no-lead-in) {
  p {
    &:first-child {
      font-size: 1.6rem;
      font-weight: normal;
    }

    @include breakpoint(small) {
      &:first-child {
        font-size: 2rem;
      }
    }
  }
}
