// These rules allow us to stick a span in the label without the positioning of the checkbox messing up
.pretty.p-icon .state .icon,
.pretty .state label:before,
.pretty .state label:after {
  top: 0.15em;
}

// Why does the pretty stylesheet have to mess with the font weight?
.pretty .state label {
  font-weight: 500;
}

// Replacing the text indent with left padding so that our additional label text span lines up
.pretty .state label {
  text-indent: 0;
  padding-left: 1.5em;
}

// To ensure that all the text including the additional label span text wraps
.pretty {
  white-space: initial;
}

// Tie height of checkbox/radio to rem - helps with display issue where the checkbox/radio distorts on zoom
.pretty .state label {
  &:before, &:after {

    width: 1.6rem;
    height: 1.6rem;
  }
}

.pretty .state.p-primary {
  position: relative;
}

// Reduce size slightly of icon for checkbox
.pretty.p-icon .state .icon {
  font-size: 1.4rem;
}

// Gives radio fill a slight margin
.pretty {

  input[type="radio"]:checked {

    & ~ .state.p-primary label:after {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0.2rem
    }
  }

  // Adds an outline on focus for better accessibility
  input[type="checkbox"]:focus {

    & ~ .state.p-primary label:before {
      outline: var(--theme-highlight-color) solid 2px;
    }
  }
}
