@include bem-block(number) {
  color: brand-color(ebony-900);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
}

@include bem-block(number, $modifier: bold) {
  font-weight: 700;
}

@include bem-block(number, $modifier: medium) {
  font-size: 4.0rem;
  line-height: 1.2;
}

@include bem-block(number, $modifier: large) {
  font-size: 6.0rem;
  line-height: 1.2;
}
