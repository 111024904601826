@include bem-block(avatar-tag) {
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  transition: 0.2s all ease;
  background-color: white;
  color: $ebony-90;
  position: relative;
  border-radius: 2em;
  -webkit-font-smoothing: inherit;
  border: 1px solid $sage-60;
  min-width: unset;
  font-size: 1.3rem;
  margin: 7.5px 7.5px 0 0;
  height: 3rem;
  line-height: 2.8rem;
  white-space: nowrap;
  vertical-align: top;

  @include bem-element(avatar-text) {
    width: calc(3rem - 2px);
    text-align: center;
    background-color: $sage-60;
    color: #FFFFFF;
    border-radius: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    display: inline-block;
    font-size: 1.4rem;

    + .avatar-tag__text {
      padding-left: 0.7rem;
    }
  }

  @include bem-element(text) {
    display: inline-block;
    vertical-align: top;
    max-width: 120px;
    padding: 0 1em;
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
