@include bem-block(chip) {
  background-color: brand-color(ivory-200);
  border-radius: 1em;
  color: brand-color(grey-500);
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.45;
  padding: 0.5em 1em;
  text-transform: uppercase;
}

@include bem-block(chip, $modifier: emphasis) {
  background-color: brand-color(purple-100);
  color: brand-color(purple-500);
}

@include bem-block(chip, $modifier: error) {
  background-color: brand-color(red-100);
  color: brand-color(red-500);
}

@include bem-block(chip, $modifier: info) {
  background-color: brand-color(blue-100);
  color: brand-color(blue-500);
}

@include bem-block(chip, $modifier: success) {
  background-color: brand-color(green-100);
  color: brand-color(green-500);
}

@include bem-block(chip, $modifier: warning) {
  background-color: brand-color(yellow-100);
  color: brand-color(yellow-600);
}
