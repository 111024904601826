@include bem-block(admin-nav) {

  @include bem-element(item) {
    color: brand-color("white");
    cursor: pointer;
    display: block;
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2.5px;
    line-height: 1.3;
    padding: 16px 24px;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: brand-color(green-500);
    }
  }

  @include bem-element(item, $modifier: active) {
    background-color: brand-color(green-500);
  }

  @include bem-element(icon) {
    color: brand-color(white-050);
    font-size: 2.0rem;
    margin-right: 16px;
    text-align: center;
  }

  @include bem-element(nested-items) {
    margin: 8px 0;
    padding: 0 16px;
  }

  @include bem-element(nested-item) {
    color: brand-color(white-050);
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: initial;
    line-height: 1.6;
    padding: 4px 18px;
    text-transform: initial;

    &:before {
      content: "\2022";
      display: inline-block;
      font-weight: 700;
      margin-right: 24px;
      text-align: center;
    }

    &:hover {
      background-color: brand-color(sage-600);
      color: white;
    }
  }

  @include bem-element(nested-item, $modifier: active) {
    color: white;
  }
}
