@include bem-block(external-2-col) {
  display: flex;

  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  @include bem-element(main) {
    flex-grow: 1;

    @include breakpoint(small) {
      margin-right: 30px;
    }

    @include breakpoint(medium) {
      margin-right: 85px;
    }
  }

  @include bem-element(sidebar) {

    @include breakpoint(small) {
      flex-shrink: 0;
      width: 300px;
    }
  }
}
