/**
  NOTE (DomSamphier): Although onboarding portals are actually just careers sites
  they are not quite put together in the same way, and there is need for a couple of
  css "overrides".
 */

@include bem-block(onboarding-collapsible-card) {
  background-color: #FFFFFF !important;
}

@include bem-block(onboarding-collapsible-card, $modifier: selected) {
  background-color: var(--theme-primary-color) !important;
  color: var(--theme-primary-font-color) !important;
}

@include bem-block(onboarding-collapsible-stage-section) {
  @include bem-element(link) {
    @include theme-color(highlight);
    cursor: pointer;
    line-height: 1.6;
    text-decoration: underline;
  }
}
