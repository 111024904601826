@include bem-block(admin-tile) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;;
  padding: $admin-spacing;

  @include bem-element(label) {
    text-transform: uppercase;
    color: $chalice;
    margin: 0;
    font-weight: 600;
    font-size: 1.7em;
  }

  @include bem-element(value) {
    margin: 0;
    font-size: 2.8em;
    line-height: 1.2;
  }

  @include bem-element(value, $modifier: currency) {
    &:before {
      content: attr(data-currency-icon);
      position: relative;
      font-size: 0.7em;
      top: -0.3em;
      right: 0.1em;
      color: $chalice;
    }
  }

  @include bem-element(value, $modifier: percent) {
    &:after {
      content: '%';
      position: relative;
      color: $chalice;
      font-size: 0.7em;
    }
  }
}

@include bem-block(admin-tile, $modifier: large) {
  font-size: 1.5rem;
  @include bem-element(label) {
    font-size: 1em;
  }
}