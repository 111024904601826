.slick-dot-hiring-process {
  li button:before,
  li.slick-active button:before {
    color: transparent;
    opacity: 1;
  }
  li button:before{
    background-color: var(--theme-secondary-color);
    border: 4px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    width: 20px;

  }
  li.slick-active button:before {
    background-color: var(--theme-highlight-color);
  }
}

