@include bem-block(sticky-tabs) {
  position: relative;
  top: 0;
  @include breakpoint(medium) {
    position: sticky;
    top: 72px;
    z-index: 9;
    // Trying to target internet explorer because sticky doesn't work so it just needs to always sit at the top of its
    // element
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      top: 0;
    }
  }
}
