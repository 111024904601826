trix-toolbar {
  button[data-trix-attribute="strike"],
    //button[data-trix-attribute="href"],
  button[data-trix-action="decreaseNestingLevel"],
  button[data-trix-action="increaseNestingLevel"],
  button[data-trix-attribute="heading1"],
  button[data-trix-attribute="quote"],
  button[data-trix-attribute="code"] {
    display: none;
  }
}

trix-editor {
  line-height: 1.5;
  max-height: 400px;
  overflow-y: auto;
  padding: 0.7em 1em; // to set to the same padding to be consistent with normal input text
  -webkit-font-smoothing: subpixel-antialiased;

  a {
    color: map-get($color-list, info);
  }
}

trix-editor:empty:not(:focus)::before {
  opacity: 0.5;
}

trix-toolbar .trix-button--icon-heading-2::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M5%204v3h5.5v12h3V7H19V4z%22%2F%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22%2F%3E%3C%2Fsvg%3E)
}

trix-toolbar .trix-button--icon-heading-3::before {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h24v24H0V0z%22%2F%3E%3Cpath%20d%3D%22M9%204v3h5v12h3V7h5V4H9zm-6%208h3v7h3v-7h3V9H3v3z%22%2F%3E%3C%2Fsvg%3E)
}

.trix-output {
  a {
    color: map-get($color-list, info);
  }
}

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    > action-text-attachment,
    > .attachment {
      flex: 1 0 33%;
      padding: 0 0.5em;
      max-width: 33%;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      > action-text-attachment,
      > .attachment {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  ul {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  action-text-attachment {
    .attachment {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }
}

trix-editor {
  word-break: break-word;
  background-color: #FFF;
  border-radius: 0;
  &:focus {
    outline: none !important;
    outline-offset: 0 !important;
  }
}

.trix--large {
  min-height: 200px;
}

.rich-text-area {
  trix-toolbar {
    button[data-trix-action="decreaseNestingLevel"],
    button[data-trix-action="increaseNestingLevel"] {
      display: none;
    }
  }
  trix-editor {
    min-height: 200px;
  }

}

.rich-text-area.rich-text-area--error {
   trix-editor {
    border: 1px red solid;
  }
}

.attachment__caption, .attachment__toolbar {
  display: none;
}

trix-toolbar {
  .trix-button-group {
    background-color: #FFF;
    border-radius: 0;
    margin-bottom: 0;
    border: none;
}

  .trix-button {
    border-bottom: none;
    padding: 11px 0;
    height: 100%;
    &:hover {
      background: #EFE9D7;
    }
  }

  .trix-button-row {
    border: 1px solid #9B988F;
    border-bottom: none;
    background: white;
  }

  .trix-button:not(:first-child) {
    border: none;
  }

  .trix-button--icon::before {
    top: 7px;
    height: 27px;
    opacity: 0.4;
  }

  .trix-button.trix-active {
    background: #278753 !important;
    &:hover {
      background: #278753;
    }
    &:before {
      filter: brightness(0) invert(1);
    }
  }

  .trix-button.trix-button--dialog{
    padding: 8px;
  }

  /* We do our own toolbar buttons so hide theirs */
  .trix-button-group--file-tools {
    display: none;
  }
}

figure.attachment--preview img {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
  display: block;
}
