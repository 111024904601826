@include bem-block(dropzone) {
  background: transparent;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: brand-color("cream");
  }
  &:focus {
    outline: none;
  }
}

// The order of rejected / accepted is important
@include bem-block(dropzone, $modifier: rejected) {
  border-color: brand-color(brick-red);
  color: brand-color(brick-red);
}

@include bem-block(dropzone, $modifier: accepted) {
  border-color: brand-color(sea-green);
  color: brand-color(sea-green);
}
