$width: 32px;
$height: 26px;
$border-width: 3px;
$border-color: #8e9293;

@include bem-block(mobile-menu) {
  width: $width;
  border-top: $border-width solid $border-color;
  position: relative;
  height: $height;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:before,
  &:after {
    content: '';
    border-bottom: $border-width solid $border-color;
    width: $width;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
  }
  &:before {
    top: 100%;
  }
  &:after {
    top: 47%;
  }
}

@include bem-block(mobile-menu, $modifier: active) {
  border-top-color: transparent;
  &:before {
    transform: rotate(-405deg) translateY(-14px) translateX(6px);
  }
  &:after {
    transform: rotate(405deg) translateY(3px) translateX(-5px);
  }
}