@include bem-block(definition-list) {
  dt {
    padding-bottom: 0.3em;
    display: block;
    font-weight: 500;
    margin: 25px 0 5px;
    &:after {
      content: ':';
    }
  }

  dd {
    padding-bottom: 0.3em;
    display: block;
    margin: 0;
    font-weight: 300;
  }
}
