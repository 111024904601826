$admin-spacing: 15px;
$border-radius: 3px;
$external-spacing: 15px;
$marketing-spacing: 15px;

$padding-input-vertical: 0.7em;
$padding-input-horizontal: 1em;

$status-icons: (
  warning: '\f071',
  info: '\f05a',
  success: '\f058',
  error: '\f057',
);

// The Thumbtack grid size. This is mainly used to determine spacing for collections of components.
$tt-grid-size: 8px;

$admin-sidebar-width: $tt-grid-size * 30;
