@keyframes fade-modal-in {
  0% {
    opacity: 0;
  }
}

@include bem-block(modal) {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @include bem-element(title) {
    padding-right: $admin-spacing * 2;
    font-size: 2.3rem;
    font-weight: 500;
    color: $theme-color-dark;
    margin: -10px 0 25px;
  }

  @include bem-element(close) {
    cursor: pointer;
    border: none;
    padding: 15px 20px;
    text-align: center;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease;
    font-family: inherit;
    font-weight: bold;
    font-size: 3rem;
    color: $alto;
    background-color: #FFF;
    border-radius: 0 10px 0 0;

    &:hover, &:focus {
      outline: none;
      color: $mortar;
    }
  }

  @include bem-element(overlay) {
    z-index: auto;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    animation-name: fade-modal-in;
    animation-duration: 0.2s;
  }

  @include bem-element(container) {
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;

    &:focus {
      outline: none;
    }
  }

  @include bem-element(dialog) {
    position: relative;
    width: 100%;
    border: 1px solid #e5e5e5;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: $admin-spacing * 2;
    animation-name: fade-modal-in;
    animation-duration: 0.2s;
    margin: 0;
    transform: translateX(-50%);
    left: 50%;

    &:focus {
      outline: none;
    }

    @include breakpoint(tiny) {
      max-width: 768px;
      padding: $admin-spacing * 3;
    }
  }
}

@each $size in map-keys($breakpoint-sizes) {
  @include bem-block(modal, $modifier: $size) {
    @include bem-element(dialog) {
      max-width: map-get($breakpoint-sizes, $size);
    }
  }
}
