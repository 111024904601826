@include bem-block(filter) {
  position: initial;

  @include breakpoint(small) {
    position: relative;
  }

  @include bem-element(button) {
    background: white;
    border: 1px solid $alto;
    color: brand-color(xanadu-grey);
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.5;
    max-width: 100%;
    padding: 0.95em 1em;
    transition: 0.2s all ease;
    white-space: nowrap;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
    }
  }

  @include bem-element(caret) {
    font-size: 1.6em;
  }

  @include bem-element(dropdown) {
    @include box-shadow();
    background: white;
    bottom: 0;
    left: 0;
    min-width: 100%;
    overflow-y: auto;
    padding: 80px 20px 120px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;

    @include breakpoint(small) {
      bottom: auto;
      left: auto;
      max-height: 300px;
      padding: 0;
      position: absolute;
      right: auto;
      top: auto;
    }
  }

  @include bem-elements((header, footer)) {
    background: white;
    left: 0;
    position: fixed;
    padding: 20px;
    right: 0;
    z-index: 3;
  }

  @include bem-element(header) {
    border-bottom: 1px solid #CCC;
    top: 0;
  }

  @include bem-element(link) {
    color: map-get($color-list, info);
  }

  @include bem-element(dropdown-search) {
    border: none;
    background: white;
  }

  @include bem-element(dropdown-item) {
    position: relative;
  }

  @include bem-element(dropdown-item-label) {
    display: block;
    color: brand-color(xanadu-grey);
    cursor: pointer;
    font-weight: 500;
    padding: 0.9em 1em;
    transition: all 0.2s ease-in-out;
    text-transform: none;

    &:hover {
      @include theme-background-color(highlight);
      color: white;
    }
  }

  @include bem-element(dropdown-item-label, $modifier: hover) {
    @include theme-background-color(highlight);
    color: white;
  }

  @include bem-element(dropdown-item-label, $modifier: active) {
    @include theme-background-color(highlight);
    color: white;
  }

  @include bem-element(dropdown-item-checkbox) {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  @include bem-element(footer) {
    bottom: 0;
    border-top: 1px solid #CCC;
    text-align: center;
  }
}

@include bem-block(filter, $modifier: active) {
  @include bem-element(button) {
    @include theme-border-color(highlight);
  }
}
