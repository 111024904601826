@import 'colors/brand';
@import 'colors/theme';

/* --------------------------------------------------
  Greys
-------------------------------------------------- */
$nero: #222;
$charcoal: #444;
$mortar: #555;
$grey: #777;
$suva: #888;
$nobel: #999;
$chalice: #AAA;
$silver: #BBB;
$alto: #CCC;
$gainsboro: #DDD;
$smoke: #EEE;
$white-smoke: #FAFAFA;
$wild-sand: #F4F4F4;

/* --------------------------------------------------
  Blues
-------------------------------------------------- */
$blue: #4D9DE0;

/* --------------------------------------------------
  NEW!
-------------------------------------------------- */
$color-list: (
  error: brand-color(brick-red),
  warning: brand-color(mustard-yellow),
  success: brand-color(sea-green),
  info: $blue,
  default: $chalice,
  'white': #FFFFFF,
  suva: $suva,
);

$state-button-color-list: (
  error: brand-color(brick-red),
  warning: brand-color(mustard-yellow),
  success: brand-color(sea-green),
  info: $blue,
  default: $chalice
);

$state-button-color-list-light: (
  error: lighten(brand-color(brick-red), 35%),
  warning: lighten(brand-color(mustard-yellow), 35%),
  success: lighten(brand-color(sea-green), 58%),
  info: lighten($blue, 35%),
  default: $smoke
);

$theme-color-dark: brand-color("dark-green");
$theme-color: brand-color(sea-green);

$alert-keys: success info warning error;
$alert-colors: (
  success-background-color: #dff0d8,
  success-color: #3c763d,
  success-border-color: #d6e9c6,
  info-background-color: #d9edf7,
  info-color: #31708f,
  info-border-color: #bce8f1,
  warning-background-color: #fff7d0,
  warning-color: #998d44,
  warning-border-color: #e9deab,
  error-background-color: #f2dede,
  error-color: #A94442,
  error-border-color: #ebccd1
);

$admin-main-areas: overview small large;
