@include bem-block(admin-mobile-navigation) {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  // Ensure the mobile nav sits above the drawer overlay that it creates
  //
  z-index: 21;

  @include breakpoint(medium) {
    display: none;
  }

  @include bem-element(item) {
    background: $sage-50;
    border-top: 8px solid $sage-40;
    height: 100%;
    flex-grow: 1;
    min-height: 60px;
    display: flex;
    justify-content: center;
  }

  @include bem-element(item, $modifier: selected) {
    border-top-color: $green-50;
  }
}