/*
  Styles used in the two column sidebar.

  These styles are shared between the mobile and desktop versions of the nav.

  You will notice a number of nested bp3- prefixed class names in the new sidebar stylesheets. I wanted to remove
  these in favour of my own classes (e.g something like pp-active instead of bp3-active). However, I was unable
  to control the class names of some of the TT components being used in the new sidebar components.

  In light of that it made more sense to just be consistent rather than using a combination of both.
 */

$secondary-sidebar-transition-ease: cubic-bezier(0.4, 1, 0.75, 0.9) !default;
$secondary-sidebar-transition-duration: 150ms !default;
$secondary-sidebar-transition: ($secondary-sidebar-transition-duration * 2) $secondary-sidebar-transition-ease !default;

@include bem-block(admin-secondary-sidebar-desktop) {
  display: none;
  padding: 0;
  background-color: brand-color(sage-500);
  height: 100%;
  min-width: $tt-grid-size * 8;
  z-index: 10;

  @include breakpoint(medium) {
    display: block;
    grid-area: secondary-sidebar;
  }
}

@include bem-block(admin-secondary-sidebar) {
  height: 100%;
  overflow-y: scroll;
  background-color: brand-color(sage-500);
  @include bem-element(nav) {
    min-height: 100%;
    color: $white;
    background-color: brand-color(sage-500);
    transition: width $secondary-sidebar-transition;

    &.bp3-condensed {
      width: $tt-grid-size * 8;
    }
  }

  @include bem-element(wrapper) {
    height: 100%;

    .admin-secondary-sidebar__nav {
      height: 100%;
      z-index: 1;
      position: fixed;
      overflow-y: scroll;
      padding: 0 12px;
      @include hide_scrollbar;
    }
  }

  @include bem-element(nav-header) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px 16px 16px;
    z-index: 10;
    position: sticky;
    top: 0;
    background-color: brand-color(sage-500);
    height: 72px;
    border-bottom: 1px solid brand-color(sage-600);
    margin-bottom: 16px;

    &.bp3-condensed {
      padding: 16px;
      justify-content: center;
    }
  }

  @include bem-element(nav-primary-item) {
    color: $white;
    background-color: brand-color(sage-500);
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: flex-start;
    padding: 8px 12px;
    width: 100%;
    transition: background-color $secondary-sidebar-transition-duration $secondary-sidebar-transition-ease;

    &:hover,
    &.bp3-force-hover {
      background: brand-color(sage-600);
    }

    &:focus,
    &.bp3-force-focus {
      outline-offset: -2px;
    }

    &.bp3-active {
      background: $green-50;

      &:hover,
      &.bp3-force-hover {
        background: $green-60;;
      }
    }

    & > .bp3-icon-custom {
      margin-left: $tt-grid-size;
    }

    .bp3-icon-custom {
      line-height: ($tt-grid-size * 2);
      flex: none;
    }

    .admin-secondary-sidebar__nav-primary-item-title {
      display: flex;
      flex-wrap: nowrap;
      align-content: flex-start;
      align-items: center;
      overflow: hidden;

      & > .bp3-icon-custom {
        margin-right: 12px;
      }
    }

    .bp3-body-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }
  }

  @include bem-element(nav-primary-item-children) {
    padding-bottom: $tt-grid-size;
  }

  @include bem-element(nav-secondary-item) {
    color: rgba(255, 255, 255, 0.6);
    background-color: brand-color(sage-500);
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 4px 20px;
    line-height: 32px;
    height: 28px;
    margin: 0 0 0 20px;
    border-left: 1px solid #6A7D74;

    .bp3-caption-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }

    &:hover,
    &.bp3-force-hover {
      background-color: brand-color(sage-600);
      color: $white;
      border-left: 1px solid $white;
    }

    &.bp3-active {
      color: $white;
      border-left: 1px solid $white;

      .bp3-caption-text {
        font-weight: 500;
      }
    }

    &:focus,
    &.bp3-force-focus {
      outline-offset: -2px;
    }

    &.bp3-condensed {
      margin: 0 20px;
      padding: 0 0 0 20px;
    }
  }
}

button.admin-secondary-sidebar__nav-primary-item {
  cursor: pointer;
}
