// TODO: ensure these files are distributed in thumbtack if we really want to import them
@import '~@pinpointhq/thumbtack/src/common/variables';
@import '~@pinpointhq/thumbtack/src/common/custom-colors';

@include bem-block(devise) {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;

  @include bem-elements((main, upsell)) {
    width: 100%;

    @include breakpoint(small) {
      width: 50%;
    }
  }

  @include bem-element(main) {
    background-color: $ivory-05;
    padding: 6.9vw; // 100px at 1440px wide
  }

  @include bem-element(logomark) {
    display: inline-block;
    margin-bottom: 6.9vw; // 100px at 1440px wide
  }

  @include bem-element(header) {
    margin-bottom: 2.8vw; // 40px at 1440px wide
  }

  @include bem-element(upsell) {
    display: none;
    @include breakpoint(small) {
      background: url('https://res.cloudinary.com/infuse-group/image/upload/e_improve,w_800,h_1200,c_thumb,f_auto,q_auto,g_auto:none/v1576578265/Pinpoint/Devise/06.04_-_PinPoint___Oliver_0166_8084.jpg') center center;
      background-size: cover;
      display: block;
    }
  }
}
