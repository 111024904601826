/*
  Prevent the stage carousel from growing too wide
  Dimensions in here are taken from the external 2 col styles
 */
@include bem-block(external-stage-carousel) {
  /*
    Padding around carousel: 20px x 2
   */
  width: calc(100vw - 40px);

  /*
    Sidebar: 300px
    Main Right Margin: 30px
    Padding around carousel: 30px x 2
   */
  @include breakpoint(small) {
    width: calc(100vw - 390px);
  }

  /*
  Sidebar: 300px
  Main Right Margin: 85px
  Padding around carousel: 30px x 2
 */
  @include breakpoint(medium) {
    width: calc(100vw - 445px);
  }

  /*
    External content now has fixed width so we can fix this too
  */
  @include breakpoint(large) {
    width: calc(1280px - 445px);
  }

  @include bem-element(stage) {
    padding: 16px 8px 32px;
  }

  @include bem-element(stage-position) {
    @include theme-color(highlight);
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  @include bem-element(stage-name) {
    color: brand-color(xanadu-grey);
    font-size: 2.0rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
  }
}
