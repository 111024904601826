@include bem-block(switch) {

  @include bem-element(text) {
    line-height: 31px;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
  }

  @include bem-element(button) {
    width: 58px;
    height: 31px;
    position: relative;
    display: inline-block;
    vertical-align: middle;

    input {
      display:none;

      &:checked + .switch__slider {
        background-color: $theme-color;

        &:before {
          transform: translateX(26px);
        }
      }

      &:focus + .switch__slider {
        box-shadow: 0 0 1px $theme-color;
      }
    }
  }

  @include bem-element(slider) {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 31px;
    background-color: $gainsboro;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }
}

@include bem-block(switch, $modifier: 'disabled') {
  opacity: 0.5;

  @include bem-element(slider) {
    cursor: not-allowed;
  }
}