@include bem-block(banner) {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 20px 20px 60px;

  @include bem-element(title) {
    margin: 0;
  }

  @include bem-element(close) {
    @include font-awesome-font;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    color: $mortar;
    &:hover {
      color: $grey;
    }
    &:before {
      content: '\f00d';
    }
  }

  @include bem-element(link) {
    &:hover {
      text-decoration: underline;
    }
  }
}

@each $colorname, $color in $color-list {
  @include bem-block(banner, $modifier: $colorname) {
    border-left: 5px solid $color;
    background: lighten($color, 25%);
    &:before {
      @include font-awesome-font;
      color: $color;
      font-weight: 300;
      content: map-get($status-icons, $colorname);
      position: absolute;
      top: 15px;
      left: 20px;
    }
  }
}
