@charset "UTF-8";

// Includes custom stylesheets to overwrite styles from node modules
// https://github.com/rails/webpacker/blob/master/docs/assets.md

/* https://www.creativebloq.com/web-design/manage-large-css-projects-itcss-101517528 */
/* --------------------------------------------------
  Settings
-------------------------------------------------- */
@import 'settings/breakpoints';
@import 'settings/colors';
@import 'settings/variables';

/* --------------------------------------------------
  Tools
-------------------------------------------------- */
@import 'tools/bem_mixins';
@import 'tools/box_shadow';
@import 'tools/breakpoints';

/* --------------------------------------------------
  Generic
-------------------------------------------------- */
@import 'generic/generic';
@import 'helpers/helpers';

/* --------------------------------------------------
  Design System
-------------------------------------------------- */
@import "~@pinpointhq/thumbtack/dist/thumbtack";
// TODO: ensure this file is distributed in thumbtack if we really want to import it
@import '~@pinpointhq/thumbtack/src/common/custom-colors';

/* --------------------------------------------------
  Temporary styles changes in order to get through v1 of visual testing
-------------------------------------------------- */
body {
  color: $mortar;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
}

/* --------------------------------------------------
  Note: The elements / objects need to slowly be phased out in favour of the design system
-------------------------------------------------- */
@import 'elements/elements';
@import 'objects/objects';

/* --------------------------------------------------
  Components
-------------------------------------------------- */
// Node Modules
@import 'animate.css';
@import 'plyr/dist/plyr.css';
@import 'pretty-checkbox/dist/pretty-checkbox.css';
@import 'react-big-calendar/lib/css/react-big-calendar.css';
@import 'rc-time-picker/assets/index.css';
@import 'rc-slider/assets/index.css';
@import 'react-dates/lib/css/_datepicker.css';
@import '~react-intl-tel-input/dist/main';
@import 'react-table/react-table.css';
@import "react-toggle/style.css";
@import "trix/dist/trix.css";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~prismjs/themes/prism-coy";
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";

// Image gallery on careers sites
@import 'photoswipe/dist/photoswipe.css';

@import 'components/components';

/* --------------------------------------------------
  Trumps
-------------------------------------------------- */
// Node Modules
@import 'trumps/apexcharts';
@import 'trumps/circle_picker';
@import 'trumps/intl_tel_input';
@import 'trumps/plyr';
@import 'trumps/pretty';
@import 'trumps/react_big_calendar';
@import 'trumps/rc_time_picker';
@import 'trumps/rc_slider';
@import 'trumps/react_dates';
@import 'trumps/react_color';
@import 'trumps/react_grid_layout';
@import 'trumps/react_select';
@import 'trumps/react_table';
@import 'trumps/progress_bar';
@import 'trumps/sweet_alert_2';
@import 'trumps/trix';
@import 'trumps/typing_indicator';

// Custom
@import 'trumps/action_bar_label';
@import 'trumps/background_color';
@import 'trumps/cronofy_ui';
@import 'trumps/external';
@import 'trumps/fixed_height';
@import 'trumps/hide';
@import 'trumps/indicator';
@import 'trumps/spacing';
@import 'trumps/sticky_tabs';
@import 'trumps/text';
@import 'trumps/turbolinks_progress_bar';
@import 'trumps/slate';
@import 'trumps/slick_carousel';
@import 'trumps/stripe_payment_form';
