@include bem-block(oval) {
  background-color: brand-color("white");
  border-radius: 50%;
  color: brand-color(grey-600);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
  height: 40px;
  line-height: 1.6;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out;
  width: 40px;

  &:before {
    content: attr(data-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: brand-color(ivory-100);
  }
}

@include bem-block(oval, $modifier: indicating) {
  color: brand-color(ebony-900);
  font-weight: 700;
}

@include bem-block(oval, $modifier: active) {
  background-color: brand-color(apricot-500);
  color: brand-color("white");

  &:hover {
    background-color: brand-color(apricot-500);
    color: brand-color("white");
  }
}
