@include bem-block(external-form) {
  @include bem-element(fieldset) {
    border: none;
    margin: 40px 0;
    padding: 0;

    @include breakpoint(small) {
      margin: 100px 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include bem-element(legend) {
    color: brand-color('charcoal');
    display: block;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 20px;
    width: 100%; // To kick Safari up the arse because even with display block its not 100%...

    @include breakpoint(small) {
      font-size: 2.4rem;
      margin-bottom: 30px;
    }
  }

  @include bem-element(legend-index) {
    @include theme-color(highlight);
    font-size: 1.4rem;
    font-weight: 700;
    margin-right: 10px;
  }

  @include bem-element(sub-heading) {
    font-size: 1.8rem;
    font-weight: 700;

    span {
      display: inline-block;
      color: rgba(0, 0, 0, 0.2);
      font-size: 0.75em;
      letter-spacing: 1px;
      margin-left: 10px;
      text-transform: uppercase;
      word-break: break-word;
    }
  }

  @include bem-element(text) {
    color: brand-color(xanadu-grey);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
  }

  @include bem-element(label) {
    color: brand-color(xanadu-grey);
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1em;
    text-transform: initial;

    span:not(.external-form__label--title) {
      display: block;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 0.2em;
    }
  }

  @include bem-element(label, $modifier: inline) {
    display: inline-block;
  }

  @include bem-element(label, $modifier: optional) {
      color: #737873; // This used to be a lighter color but it breaks WCAG color contrast rules
      display: inline-block;
      font-size: 1.2rem;
      letter-spacing: 1px;
      margin-left: 10px;
      text-transform: uppercase;
      word-break: break-word; // To stop really long URLs and such from making the parent too wide
  }

  @include bem-element(label, $modifier: required) {
    &:after {
      @include theme-color(highlight);
      content: '*';
      display: 'inline';
      font-size: 1.4rem;
      margin-left: 5px;
    }
  }

  @include bem-element(label, $modifier: optional-onboarding) {
    &:after {
      content: '(Optional)';
      color: #737873; // This used to be a lighter color but it breaks WCAG color contrast rules
      display: inline;
      font-size: 1.2rem;
      margin-left: 10px;
      word-break: break-word; // To stop really long URLs and such from making the parent too wide
    }
  }
}
