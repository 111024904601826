$burger-thickness: 4px;
$burger-navigation-transition: max-height 0.2s ease-in-out;

@include bem-block(hamburger-menu) {
  @include bem-element(burger) {
    $width: 28px;
    margin: $marketing-spacing;
    position: absolute;
    right: $marketing-spacing;
    top: $marketing-spacing;
    display: inline-block;
    border: 0;
    background: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    border-bottom: $burger-thickness solid brand-color(grey-500);
    width: $width;
    transition: border-bottom 0.3s ease-in-out;
    // Fix for extra space in Firefox
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    &:before {
      content: "";
      display: block;
      border-bottom: $burger-thickness solid brand-color(grey-500);
      width: 100%;
      margin-bottom: 5px;
      transition: transform 0.3s ease-in-out;
    }

    &:after {
      content: "";
      display: block;
      border-bottom: $burger-thickness solid brand-color(grey-500);
      width: 100%;
      margin-bottom: 5px;
      transition: transform 0.3s ease-in-out;
    }

    @include breakpoint(medium) {
      display: none;
    }
  }

  @include bem-element(check) {
    display: none;

    &:checked {

      & ~ .hamburger-menu__burger {
        border-bottom: $burger-thickness solid transparent;
        transition: border-bottom 0.3s ease-in-out;

        &:before {
          transform: rotate(-405deg) translateY(3px) translateX(-3px);
          transition: transform 0.3s ease-in-out;
        }

        &:after {
          transform: rotate(405deg) translateY(-3px) translateX(-3px);
          transition: transform 0.3s ease-in-out;
        }
      }

      & ~ .hamburger-menu__navigation {
        max-height: 100%;

        .hamburger-menu__burger {
          border-bottom: $burger-thickness solid transparent;
          transition: border-bottom 0.3s ease-in-out;

          &:before {
            border-color: white;
            transform: rotate(-405deg) translateY(3px) translateX(-3px);
            transition: transform 0.3s ease-in-out;
          }

          &:after {
            border-color: white;
            transform: rotate(405deg) translateY(-3px) translateX(-3px);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }

  @include bem-element(navigation) {
    position: fixed;
    overflow: scroll;
    max-height: 0;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: brand-color(sage-500);
    z-index: 2;
    transition: $burger-navigation-transition;

    & > ul, div {
      pointer-events: all;
    }

    @include breakpoint(medium) {
      position: relative;
      width: auto;
      height: auto;
      max-height: none;
      display: flex;
      padding: 0;
      background-color: transparent;
      overflow: hidden;
    }
  }

  @include bem-element(header) {
    display: flex;
    padding: $marketing-spacing;
    text-align: left;

    @include breakpoint(medium) {
      display: none;
    }
  }

  @include bem-element(header, $modifier: dark) {
    background: brand-color(sage-600);
  }

  @include bem-element(footer) {
    padding: $marketing-spacing;
    position: absolute;
    bottom: 0;
    text-align: left;

    @include breakpoint(medium) {
      display: none;
    }
  }

  @include bem-element(logo) {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 1em 0;
    letter-spacing: -1px;
    color: #FFF;
    font-variant: small-caps;

    @include breakpoint(medium) {
      display: inline-block;
      text-align: left;
    }
  }

  @include bem-element(image) {
    height: 2.2rem;
  }
}
