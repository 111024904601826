p[data-slate-node]{
  margin: 0;
  padding: 0;
}

p[data-slate-node]:last-of-type{
  margin: 0;
  padding-bottom: 16px;
}

p[data-slate-node]:first-of-type {
  margin: 0;
  padding-top: 16px;
}
