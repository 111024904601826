@include bem-block(careers-testimonial-block) {

  @include breakpoint(small) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  @include bem-element(image-wrap) {
    margin: 0 auto 30px auto;
    max-width: 200px;

    @include breakpoint(small) {
      flex-shrink: 0;
      margin: 0 50px 0 0;
      max-width: none;
      width: 240px;
    }

    @include breakpoint(medium) {
      width: 300px;
    }

    @include breakpoint(large) {
      margin: 0 130px 0 0;
      width: 370px;
    }
  }

  @include bem-element(content) {
    max-width: 800px;
  }

  @include bem-element(quotation) {
    color: brand-color("charcoal");
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 22px 0;

    @include breakpoint(small) {
      font-size: 3.0rem;
    }
  }

  @include bem-element(attribution) {
    @include theme-color(highlight);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
  }
}
