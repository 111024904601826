@include bem-block(external-content-wrap) {
  margin: 0 auto;
  width: 100%;

  @include breakpoint(large) {
    max-width: map-get($breakpoint-sizes, 'lg');
  }
}

@include bem-block(external-content-wrap, $modifier: readable) {
  @media (min-width: 860px) {
    max-width: 860px;
  }
}
