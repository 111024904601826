@include bem-block(careers-carousel-block) {
  margin: 0;

  // remove wrapping for the parent carousel, but not the children
  & > .external-content-wrap {
    max-width: inherit;
    margin: 0;

    & > .careers-block__inner {
      padding: 0;
    }
  }

  // move dots on top of carousel item
  .slick-dots {
    margin-top: -50px;
    margin-bottom: 20px;
    bottom: 0;

    li {
      margin: 0 2px;

      button:before {
        font-size: 12px;
        color: var(--theme-primary-color);
      }
    }
  }
}
