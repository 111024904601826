@include bem-block(external-footer) {
  @include theme-background-color(secondary);
  grid-area: footer;
  padding: 60px 32px;

  @include breakpoint(small) {
    padding: 72px 32px;
  }

  @include bem-element(link) {
    // This is the lightest grey that can be used against a white background to meet the WCAG requirement for accessibility
    color: brand-color("slate-grey");
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }

    i {
      color: black;
    }
  }
}
