@include bem-block(floating-header) {
  position: fixed;
  bottom: 0;
  padding: 0;
  z-index: 11;
  left: 0;
  width: 100%;
  border-top: 1px solid $gainsboro;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  @include breakpoint(tiny) {
    top: 0;
    bottom: auto;
    border-top: none;
    border-bottom: 1px solid $gainsboro;
  }
}
