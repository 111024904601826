@include bem-block(advert-preview) {
  max-width: 600px;
  background-color: #f4f6f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  user-select: none;

  @include bem-element(introductory-text) {
    padding: 10px 20px;
  }

  @include bem-element(image) {
    display: block;
  }

  @include bem-element(detail-block) {
    padding: 10px 20px;
  }

  @include bem-element(headline) {
    font-weight: bold;
  }
}
