@include bem-block(react-select) {
  color: #555;
  position: relative;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  @include bem-element(multi-value) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @include bem-element(input) {
    @include body-font;
    font-size: 1.6rem;
    line-height: 1.6;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  @include bem-element(indicator) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @include bem-element(dropdown-indicator) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @include bem-element(value-container) {
    padding: 0;
  }

  @include bem-element(control) {
    @include default-input-styles;
    // Overwriting the default input styles to make these the same height as our inputs
    //
    padding: 0.5em 1em;

    &:hover {
      border-color: $alto;
    }
  }

  @include bem-element(control, $modifier: is-focused) {
    // This is so react select has a more obvious focus state for better accessibility
    border-color: brand-color(xanadu-grey);
    border-radius: 0;
    box-shadow: none;
  }

  @include bem-element(menu) {
    z-index: 52;
    margin-top: 0;
    border-radius: 0;
    border: 1px solid hsl(0, 0%, 80%);
    border-top: none;
  }

  @include bem-element(menu-list) {
    padding: 0;
  }

  @include bem-element(placeholder) {
    opacity: 0.5;
  }
}

@include bem-block(react-select, $modifier: error) {
  @include bem-element(control) {
    border-color: red;
  }
}