@include bem-block(careers-cards-block) {

  @include bem-element(cards) {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -16px;
  }

  @include bem-element(cards, $modifier: align-left) {
    align-items: flex-start;
    @include bem-elements((h3, p)) {
      text-align: left;
    }
  }

  @include bem-element(cards, $modifier: align-center) {
    align-items: center;
    @include bem-elements((h3, p)) {
      text-align: center;
    }
  }

  @include bem-element(cards, $modifier: align-right) {
    align-items: flex-end;
    @include bem-elements((h3, p)) {
      text-align: right;
    }
  }

  @include bem-element(card) {
    padding: 16px;
  }
}
