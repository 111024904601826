// Relies on the parent being positioned relatively

@include bem-block(indicator) {
  position: absolute;
  padding: 6px;
  background: brand-color(brick-red);
  top: 0;
  right: 7px;
  border-radius: 50%;
  z-index: 2;
}