/**
  600 is for readable text blocks
  880 is for Secondary Tab content that has a side nav e.g. Application comments
  1200 is for Page content that shouldn't be full width e.g. Edit Job form
  1280 is to be used more as a layout when you want the content to fix perfect at 1600px e.g. Dashboard
 */
$centered-content-sizes: (600, 880, 1200, 1280);

/**
  Currently used by the <CenteredContent> component
  Defined as a CSS class and not an inline style so we can use it in HTML if needs be
*/
@each $size in $centered-content-sizes {
  @include bem-block(centered-content-#{$size}) {
    margin: 0 auto;
    max-width: #{$size}px;
  }
}
