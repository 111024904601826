/*
  Styles for the two column sidebar

  You will notice a number of nested bp3- prefixed class names in the new sidebar stylesheets. I wanted to remove
  these in favour of my own classes (e.g something like pp-active instead of bp3-active). However, I was unable
  to control the class names of some of the TT components being used in the new sidebar components.

  In light of that it made more sense to just be consistent rather than using a combination of both.
 */
@include bem-block(admin-primary-sidebar-mobile) {
  background-color: $sage-50;
  @include bem-element(wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(small) {
      justify-content: flex-start;
    }
  }

  @include bem-element(nav-primary-item) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 168px;
    height: 44px;
    background-color: brand-color(sage-500);
    border-radius: 50px;
  }

  @include bem-element(nav-primary-item, $modifier: active) {
    background-color: brand-color(sage-600);
  }
}

@include bem-block(admin-primary-sidebar) {
  display: none;
  background-color: brand-color(sage-600);
  height: 100%;
  padding: 24px 12px;

  @include bem-element(nav) {
    height: 100%;
    position: fixed;
  }

  @include breakpoint(medium) {
    display: block;
    grid-area: primary-sidebar;
  }

  @include bem-element(logomark-container) {
    height: 48px;
  }

  @include bem-element(nav-link) {
    &:focus {
      outline: none;
    }
  }

  @include bem-element(nav-icon) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 50px;
    outline: none;

    &.bp3-active {
      background-color: brand-color(sage-500);
    }

    &:hover {
      border: 2px solid brand-color(sage-400)
    }
  }
}
