@include bem-block(avatar) {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  object-fit: cover;
}

@for $multiple from 1 through 24 {
  @include bem-block(avatar, $modifier: '#{$multiple}x') {
    height: #{(8 * $multiple)}px;
    width: #{(8 * $multiple)}px;
  }
}

// Enables us to switch sizes between breakpoints
@each $size in map-keys($breakpoint-sizes) {

  @for $multiple from 1 through 24 {
    @include bem-block(avatar, $modifier: '#{$size}-#{$multiple}x') {

      @media (min-width: map-get($breakpoint-sizes, $size)) {
        height: #{(8 * $multiple)}px;
        width: #{(8 * $multiple)}px;
      }
    }
  }
}
