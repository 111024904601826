h1 {
  font-size: 3.0rem;
  font-weight: 500;
  line-height: 1.3;
}
h2 {
  font-size: 2.3rem;
  font-weight: 500;
  line-height: 1.3;
}
h3 {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
}
h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.3;
}
