@include bem-block(admin-footer) {
  grid-area: footer;
  margin-bottom: 60px;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

@include bem-block(admin-footer) {
  background-color: brand-color("white");
  padding: ($tt-grid-size * 2) ($tt-grid-size * 3);

  @include breakpoint(medium) {
    padding: ($tt-grid-size * 4) ($tt-grid-size * 5);
  }
}
