//== Grid system
//
//## Define your custom responsive grid.

//** Number of max columns in the grid.
@use "sass:math";

$grid-max-columns: 12 !default;

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 16px !default;

// Flexible row
//
// Flexible rows contain your columns.

@include bem-block(frow) {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

// NOTE: (VR) fills the remaining space in a flex container. Very useful when we don't want to declare a width for a
// child element.
.frow--fill,
.bp3-frow--fill {
  flex: 1;
}

// Adds gutters to rows and keeps gutters if it switches to columns
@for $num from 1 through 10 {

  $str: 'gutters';
  @if $num > 1 {
    $str: 'gutters-#{$num}x';
  }

  @include bem-block(frow, $modifier: $str) {
    margin: -($grid-gutter-width * $num) * 0.5;

    & > * {
      padding: ($grid-gutter-width * $num) * 0.5;
    }
  }
}

// Adds gutters to rows and keeps gutters if it switches to columns
@for $num from 1 through 10 {

  // Enables us to switch between gutters on various breakpoints
  @each $size in $breakpoint-size-names {

    $breakpoint_str: 'gutters-#{$size}';
    @if $num > 1 {
      $breakpoint_str: 'gutters-#{$size}-#{$num}x';
    }

    @media (min-width: map-get($breakpoint-sizes, $size)) {
      @include bem-block(frow, $modifier: $breakpoint_str) {
        margin: -($grid-gutter-width * $num) * 0.5;
        & > * {
          padding: ($grid-gutter-width * $num) * 0.5;
        }
      }
    }
  }
}

@include bem-block(frow, $modifier: spaced) {
  margin-bottom: $grid-gutter-width * 2;
}

@include bem-block(frow, $modifier: centered) {
  justify-content: center;
  align-items: center;
  align-content: center;
}

@include bem-block(frow, $modifier: centered-column) {
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}

@include bem-block(frow, $modifier: row-start) {
  flex-direction: row;
  justify-content: flex-start;
}

@include bem-block(frow, $modifier: row-center) {
  flex-direction: row;
  justify-content: center;
}

@include bem-block(frow, $modifier: row-end) {
  flex-direction: row;
  justify-content: flex-end;
}

@include bem-block(frow, $modifier: column-start) {
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}

@include bem-block(frow, $modifier: column-center) {
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}

@include bem-block(frow, $modifier: column-end) {
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-start;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}

// Wrap
@include bem-block(frow, $modifier: wrap) {
  flex-wrap: wrap;
}

@include bem-block(frow, $modifier: wrap-reverse) {
  flex-wrap: wrap-reverse;;
}

@include bem-block(frow, $modifier: nowrap) {
  flex-wrap: nowrap;
}

// Flex-direction
@include bem-block(frow, $modifier: direction-row) {
  flex-direction: row;
}

@include bem-block(frow, $modifier: direction-reverse) {
  flex-direction: row-reverse;
}

@include bem-block(frow, $modifier: direction-column) {
  flex-direction: column;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}

@include bem-block(frow, $modifier: direction-column-reverse) {
  flex-direction: column-reverse;

  // Use a hacky media query to only target IE10+
  // We know that a block-level child is supposed to occupy the full width of the parent.
  // Chrome understands this. IE11, for whatever reason, wants an explicit request.
  // This just ensure all text etc gets wrapped
  @media all and (-ms-high-contrast:none) {
    > * {
      max-width: 100%;
    }
  }
}


// Justify-content
@include bem-block(frow, $modifier: justify-start) {
  justify-content: flex-start;
}

@include bem-block(frow, $modifier: justify-end) {
  justify-content: flex-end;
}

@include bem-block(frow, $modifier: justify-center) {
  justify-content: center;
}

@include bem-block(frow, $modifier: justify-between) {
  justify-content: space-between;
}

@include bem-block(frow, $modifier: justify-around) {
  justify-content: space-around;
}

// Align-items
@include bem-block(frow, $modifier: items-start) {
  align-items: flex-start;
}

@include bem-block(frow, $modifier: items-end) {
  align-items: flex-end;
}

@include bem-block(frow, $modifier: items-center) {
  align-items: center;
}

@include bem-block(frow, $modifier: items-baseline) {
  align-items: baseline;
}

@include bem-block(frow, $modifier: items-stretch) {
  align-items: stretch;
}

// Align-items at different breakpoints
@each $size in $breakpoint-size-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {
    @include bem-block(frow, $modifier: items-#{$size}-start) {
      align-items: flex-start;
    }

    @include bem-block(frow, $modifier: items-#{$size}-end) {
      align-items: flex-end;
    }

    @include bem-block(frow, $modifier: items-#{$size}-center) {
      align-items: center;
    }

    @include bem-block(frow, $modifier: items-#{$size}-baseline) {
      align-items: baseline;
    }

    @include bem-block(frow, $modifier: items-#{$size}-stretch) {
      align-items: stretch;
    }
  }
}



// Align-content
@include bem-block(frow, $modifier: content-start) {
  align-content: flex-start;
}

@include bem-block(frow, $modifier: content-end) {
  align-content: flex-end;
}

@include bem-block(frow, $modifier: content-center) {
  align-content: center;
}

@include bem-block(frow, $modifier: content-between) {
  align-content: space-between;
}

@include bem-block(frow, $modifier: content-around) {
  align-content: space-around;
}

@include bem-block(frow, $modifier: content-stretch) {
  align-content: stretch;
}

// Columns
$all-columns: ();

@for $denominator from 1 through $grid-max-columns {
  @for $numerator from 1 through $denominator {
    $all-columns: append($all-columns,
      '.col-#{$numerator}-#{$denominator}',
      comma);
  }
}

@for $denominator from 1 through $grid-max-columns {
  @for $numerator from 1 through $denominator {
    @each $size in $breakpoint-size-names {
      $all-columns: append($all-columns,
        '.col-#{$size}-#{$numerator}-#{$denominator}',
        comma);
    }
  }
}

#{$all-columns} {
  width: 100%;
}

// Column widths
@for $denominator from 1 through $grid-max-columns {
  @for $numerator from 1 through $denominator {
    .col-#{$numerator}-#{$denominator} {
      width: percentage(math.div($numerator, $denominator));
    }
  }
}

@each $size in $breakpoint-size-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {
    @for $denominator from 1 through $grid-max-columns {
      @for $numerator from 1 through $denominator {
        .col-#{$size}-#{$numerator}-#{$denominator} {
          width: percentage(math.div($numerator, $denominator));
        }
      }
    }
  }
}

// Column Ordering
@for $order from 1 through 10 {
  .col-order-#{$order} {
    order: $order;
  }
}

@for $order from 1 through 10 {
  @each $size in $breakpoint-size-names {
    @media (min-width: map-get($breakpoint-sizes, $size)) {
      .col-#{$size}-order-#{$order} {
        order: $order;
      }
    }
  }
}

@for $grow from 1 through 10 {
  .col-flex-grow-#{$grow} {
    flex-grow: $grow !important;

    // Use a hacky media query to only target IE10+
    // IE11 doesn't handle flex-basis: auto + flex-grow: 1 very well when the
    // width of the container isn't definite.
    @media all and (-ms-high-contrast:none) {
      flex-basis: 0%;
    }
  }
}

.col-self-end {
  align-self: flex-end;
}

// Enables us to switch between row and column direction on various breakpoints
@each $size in $breakpoint-size-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {
    @include bem-block(frow, $modifier: row-#{$size}) {
      flex-direction: row;
    }
    @include bem-block(frow, $modifier: column-#{$size}) {
      flex-direction: column;

      // Use a hacky media query to only target IE10+
      // We know that a block-level child is supposed to occupy the full width of the parent.
      // Chrome understands this. IE11, for whatever reason, wants an explicit request.
      // This just ensure all text etc gets wrapped
      @media all and (-ms-high-contrast:none) {
        > * {
          max-width: 100%;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// NEW as of Aug 2020 => Designed to be used with Frow React component
// but should be used in favour of the old way of defining gutters
// -----------------------------------------------------------------------------------------------------

$gutter-widths: (4, 8, 12, 16, 24, 32);
@each $gutter-width in $gutter-widths  {
  @include bem-block(frow, $modifier: "gutters-#{$gutter-width}") {
    $half-gutter-width-px: $gutter-width * 0.5px;

    margin: -($half-gutter-width-px);
    & > * {
      padding: $half-gutter-width-px;
    }
  }
}

$align-items-options: (flex-start, flex-end, center, baseline, stretch);
@each $align-item in $align-items-options {
  @include bem-block(frow, $modifier: "align-items-#{$align-item}") {
    align-items: $align-item;
  }
}

$justify-content-options: (flex-start, flex-end, center, space-between, space-around, initial, inherit);
@each $justify-content in $justify-content-options {
  @include bem-block(frow, $modifier: "justify-content-#{$justify-content}") {
    justify-content: $justify-content;
  }
}

$flex-wrap-options: (nowrap, wrap, wrap-reverse);
@each $flex-wrap in $flex-wrap-options {
  @include bem-block(frow, $modifier: "flex-wrap-#{$flex-wrap}") {
    flex-wrap: $flex-wrap;
  }
}
