$pinned-width: ($tt-grid-size * 30);
$unpinned-width: ($tt-grid-size * 9);

@include bem-block(admin-body) {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr minmax(60px, max-content);

  grid-template-areas:
    "main"
    "footer";

  @include breakpoint(medium) {
    grid-template-columns: $pinned-width calc(100% - #{$pinned-width});
    grid-template-rows: minmax(($tt-grid-size * 9), max-content) 1fr minmax(60px, max-content);

    grid-template-areas:
      "sidebar header"
      "sidebar main"
      "sidebar footer";
  }
}

@include bem-block(admin-body, $modifier: sidebar-unpinned) {
  @include breakpoint(medium) {
    grid-template-columns: $unpinned-width calc(100% - #{$unpinned-width});
  }
}

@include bem-block(admin-body, $modifier: fullscreen) {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(($tt-grid-size * 9), max-content) 1fr minmax(60px, max-content);

  grid-template-areas:
    "main"
    "footer";

    @include breakpoint(medium) {
      grid-template-areas:
        "header"
        "main"
        "footer";
    }
}

@include bem-block(admin-body, $modifier: fullscreen-no-header-or-footer) {
  grid-template-areas:
    "main"
}

$primary-sidebar-width: 68px;
$secondary-sidebar-width: 208px;
$two-column-sidebar-width: ($primary-sidebar-width + $secondary-sidebar-width);
$secondary-sidebar-unpinned-width: 64px;

@include bem-block(admin-body, $modifier: two-column-sidebar) {
  @include breakpoint(medium) {
    grid-template-columns: $primary-sidebar-width $secondary-sidebar-width calc(100% - $two-column-sidebar-width);
    grid-template-rows: minmax(($tt-grid-size * 9), max-content) 1fr minmax(60px, max-content);

    grid-template-areas:
      "primary-sidebar secondary-sidebar header"
      "primary-sidebar secondary-sidebar main"
      "primary-sidebar secondary-sidebar footer";
  }
}

@include bem-block(admin-body, $modifier: two-column-sidebar-unpinned) {
  @include breakpoint(medium) {
    grid-template-columns: $primary-sidebar-width $secondary-sidebar-unpinned-width calc(100% - ($primary-sidebar-width + $secondary-sidebar-unpinned-width));
  }
}
