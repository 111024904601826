
// Ensure we have enough space to display our text content
.rbc-timeslot-group {
  min-height: 86px;
}

// Change from the default blue
.rbc-event {
  background-color: brand-color("dark-green");
}

// We're setting the content as the time period, so we dont need to show the label which also indicates the timeslot
.rbc-event-label {
  display: none;
}

// Some feedback on hover shows the user they can click to create
.rbc-selectable .rbc-day-slot .rbc-time-slot:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

// Make the event take up 100% width of the column
.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

// Change the look of the proposed slot when we drag to create
.rbc-slot-selection {
  font-size: 1.4rem;
  border-radius: 3px;
  background-color: lighten(brand-color("dark-green"), 20%);
  left: 1px;
  right: 1px;
}

// Change the colour of the background on todays events
.rbc-today {
  background: brand-color('ivory-50');
}

// Changes the colour of the little lines on the calendar
.rbc-timeslot-group,
.rbc-time-content > * + * > * {
  border-color: brand-color('ivory-300');
}

// Adds a border to the bottom of the header row
.rbc-time-header-content {
  border-bottom: 1px solid brand-color('ivory-300');
}

// The borders for each 15 min time period - these are slightly lighter than the hour separators
.rbc-time-slot {
  border-color: brand-color('ivory-100');
}

// Remove all borders from the header column, header title and the main body of the calendar
.rbc-header,
.event-calendar__title,
.rbc-time-content {
  border: none !important;
}

// We dont have any allday events and it just gets in the way
.rbc-allday-cell {
  display: none;
}

// Hide events that are being dragged around
.rbc-event-is-dragging {
  opacity: 0 !important;
}

// We only show the dates in the toolbar and we want them pushed to the left because we add other things to the right
.rbc-toolbar-label {
  text-align: left !important;
}

// Change the font color for the title headings
.event-calendar__title {
  color: #757269;
  border-bottom: none;
}

// Change the font and padding for the selection (the thing that shows on drag)
.rbc-slot-selection {
  color: rgb(247, 243, 231);
  font-size: 12px;
  font-weight: bold;
  padding: 8px 14px;
}


@media all and (-ms-high-contrast:none) {

  // In IE10 and IE11, containers with display: flex and flex-direction: column will not properly calculate their flexed childrens' sizes if the container has min-height but no explicit height property
  .rbc-calendar {
    height: 1400px;
  }

  /*
    NOTE: (TomLuce) There were additional rules in here concerning IE11 and its need for a flex-basis
    property however these rules are now as a style tag in the HTML as some part of the CSS compilation
    process removes the flex basis property if we define it here

    @see https://github.com/jquense/react-big-calendar/issues/1142
   */
}
