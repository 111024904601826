@include bem-block(table) {
  margin: 0;
  border-collapse: collapse;
  border: none;

  tbody {
    tr:hover {
      th, td {
        background-color: inherit;
      }
    }
  }

  th {
    font-weight: 500;
    text-align: left;
    color: $nero;
    padding: $admin-spacing * 1.6;
    border-bottom: 1px solid $smoke;
  }

  td {
    padding: $admin-spacing * 1.6;
    border-bottom: 1px solid $smoke;
  }

}

@include bem-block(table, $modifier: full) {
  width: 100%;
}

@include bem-block(table, $modifier: condensed) {
  th, td {
    padding: $admin-spacing;
  }
}

// NOTE: (TomLuce) THis is temporary as it wont be a table for much longer
@include bem-block(interview-invites-table) {
  border-collapse: collapse;
  border: none;
  margin: 0;
  table-layout: fixed;
  width: 100%;

  thead {
    display: none;

    @include breakpoint(small) {
      display: table-header-group;
    }
  }

  tr {
    border-bottom: 1px solid $smoke;
    display: block;
    padding: 10px 0;

    @include breakpoint(small) {
      border-bottom: none;
      display: table-row;
      padding: 0;
    }
  }

  th {
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    color: $nero;
    padding: $admin-spacing * 1.6;
    border-bottom: 1px solid $smoke;
  }

  td {
    border-bottom: none;
    display: block;
    margin: 10px 0;
    padding: 0;

    @include breakpoint(small) {
      border-bottom: 1px solid $smoke;
      display: table-cell;
      margin: 0;
      padding: $admin-spacing * 1.6;
    }
  }
}
