@include bem-block(button-group ) {
  display: block;
  > * {
    width: 100%;
  }
  >:first-child {
    border-radius: $border-radius $border-radius 0 0 !important;
  }
  >:last-child {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}

@include bem-block(button-group, $modifier: bordered) {
  border: 1px solid $gainsboro;
  > * {
    border-bottom: 1px solid $gainsboro !important;
    &:last-of-type {
      border-bottom: none !important;
    }
  }
}

@each $colorname, $color in $color-list {

  @include bem-block(button-group, $modifier: #{$colorname}-bordered) {
    border: 1px solid $color;
    > * {
      border-bottom: 1px solid $color !important;
      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

@each $colorname, $color in $brand-colors {

  @include bem-block(button-group, $modifier: brand-#{$colorname}-bordered) {
    border: 1px solid $color;
    > * {
      border-bottom: 1px solid $color !important;
      &:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

@each $size in $breakpoint-size-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {

    @include bem-block(button-group, $modifier: $size) {
      display: inline-flex;
      border-radius: $border-radius;
      > * {
        width: auto;
      }
      >:first-child {
        border-radius: $border-radius 0 0 $border-radius !important;
      }
      >:last-child {
        border-radius: 0 $border-radius $border-radius 0 !important;
      }

      &.button-group--bordered {
        > * {
          border-right: 1px solid $gainsboro !important;
          border-bottom: none !important;
          &:last-of-type {
            border-right: none !important;
          }
        }
      }

      @each $colorname, $color in $color-list {

        &.button-group--#{$colorname}-bordered {
          > * {
            border-right: 1px solid $color !important;
            border-bottom: none !important;
            &:last-of-type {
              border-right: none !important;
            }
          }
        }
      }

      @each $colorname, $color in $brand-colors {

        &.button-group--brand-#{$colorname}-bordered {
          > * {
            border-right: 1px solid $color !important;
            border-bottom: none !important;
            &:last-of-type {
              border-right: none !important;
            }
          }
        }
      }
    }
  }
}
