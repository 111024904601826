.ReactTable {

  .rt-resizer {
    z-index: 1;
  }

  .rt-th,
  .rt-td {
    white-space: normal;
    // Override custom some pretty styles that already exist
    // NOTE: (TomLuce) I have no idea if I can remove the offending styles so fixing my issue here...
    .pretty.p-icon .state .icon,
    .pretty .state label:before,
    .pretty .state label:after {
      top: 0;
    }
  }

  .rt-td--wrap-white-space {
    white-space: normal;
  }

  .-loading-inner {
    transition: all 0.2s ease-in-out;
  }

  .-loading.-active > div {
    transform: translateY(-50%);
  }
}

.ReactTable.-unthemed {
  border: none;

  .rt-thead {
    min-width: auto !important;
  }

  .rt-tbody {
    min-width: auto !important;
  }

  .rt-thead.-header {
    box-shadow: none;
    border-bottom: 2px solid $smoke;
  }

  .rt-thead .rt-th,
  .rt-thead .rt-td {
    padding: 1.1em 1em;
    border-right: none;
    -webkit-font-smoothing: subpixel-antialiased;
    font-weight: 500;
    text-align: left;

    &:focus {
      outline: none;
    }

    &:first-child {
      padding-left: 1.5em;
    }

    &:last-child {
      padding-right: 1.5em;
    }
  }

  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc,
  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    color: $theme-color;
    box-shadow: none;
  }

  // Important that this comes after the sort styles because we apply it when managing sort ourselves
  .rt-thead .rt-th.-disable-default-sort {
    color: $mortar;
  }

  .rt-thead .rt-th.-sorted-asc,
  .rt-thead .rt-th.-sorted-desc {
    color: $theme-color;
  }

  .rt-tbody .rt-tr-group {
    border-bottom: 1px solid $smoke;
    @include breakpoint(small) {
      &:hover {
        background: $white-smoke;
      }
    }
  }

  .rt-tbody .rt-td {
    border-right: none;
  }

  .rt-tr {
    align-items: center;
  }

  .rt-tr[data-qnt='0'] > .rt-expandable {
    visibility: hidden;
  }

  .rt-tr--muted {
    color: #9B988F;
  }

  .rt-th,
  .rt-td {
    padding: 1em;

    &:first-child {
      padding-left: 1.5em;
    }

    &:last-child {
      padding-right: 1.5em;
    }
  }

  .rt-resizer {
    width: 20px;
    right: -10px;
  }

  // SORTING ICONS - we define the standard (no interference from us) ones first then overwrite them, then define the ones
  // we apply when we start interfering

  .rt-thead .rt-th.-sort-asc {
    div {
      &:after {
        margin-left: 0.6em;
        content: '\2191';
      }
    }
  }

  .rt-thead .rt-th.-sort-desc {
    div {
      &:after {
        margin-left: 0.6em;
        content: "\2193";
      }
    }
  }

  .rt-thead .rt-th.-disable-default-sort,
  .rt-thead .rt-th.-disable-default-sort {
    div {
      &:after {
        margin-left: 0;
        content: "";
      }
    }
  }

  .rt-thead .rt-th.-sorted-desc {
    div {
      &:after {
        margin-left: 0.6em;
        content: "\2193";
      }
    }
  }

  .rt-thead .rt-th.-sorted-asc {
    div {
      &:after {
        margin-left: 0.6em;
        content: '\2191';
      }
    }
  }

  // Styling specifically for the table cells that have checkboxes
  .rt-th.-checkbox,
  .rt-td.-checkbox {
    // Need the important to overwrite the first child padding that we have on the react table header
    padding: 1em 0.5em !important;
    text-overflow: clip;

    .rt-resizable-header-content {
      text-overflow: clip;
    }
  }

  // To ensure that all checkboxes line up, no matter if the rows have a coloured state border
  .rt-tr {
    border-left: 4px solid transparent;
  }
  .rt-tr.-highlighted {
    background-color: #fcfaf2;
  }

  .pagination-bottom {
    text-align: center;
    @include breakpoint(small) {
      text-align: right;
    }
  }

  // COLOURED LEFT BORDER FOR STATE
  .rt-tbody .rt-tr.-state-default {
    border-left: 4px solid $alto;
  }

  .rt-tbody .rt-tr.-state-warning {
    border-left: 4px solid brand-color(mustard-yellow);
  }

  .rt-tbody .rt-tr.-state-info {
    border-left: 4px solid $blue;
  }

  .rt-tbody .rt-tr.-state-success {
    border-left: 4px solid brand-color(sea-green);
  }

  .rt-tbody .rt-tr.-state-error {
    border-left: 4px solid brand-color(brick-red);
  }
}


// Tables currently used on the external Jobs Index
.ReactTable.-themed {
  border: none;

  // The React Table JS auto puts an inline min-width style on (100px for each column)
  .rt-thead,
  .rt-tbody {
    min-width: auto !important;
  }

  .rt-thead.-header {
    box-shadow: none;
    border: none;

    .rt-tr {
      text-align: left;
    }
  }

  .rt-tr-group {
    border: none;

    &:nth-child(odd) {
      @include theme-background-color(secondary);
    }

    &:hover {
      cursor: pointer;
      a {
        @include theme-color(highlight);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .rt-td,
  .rt-th {
    border: none;
    line-height: 1.5;
    padding: 20px;

    &:focus,
    &:active {
      outline: none;
    }

    // To replicate padding of external-panel__inner
    &:first-child {
      padding-left: 20px;
      @include breakpoint(small) {
        padding-left: 30px;
      }
    }

    &:last-child {
      padding-right: 20px;
      @include breakpoint(small) {
        padding-right: 30px;
      }
    }
  }

  .rt-th {
    @include theme-color(highlight);
    font-size: 1.4rem;
    font-weight: bold;
  }

  // SORTING ICONS
  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc,
  .rt-thead .rt-th.-sort-desc,
  .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
  }

  .rt-thead .rt-th.-sort-desc .rt-resizable-header-content,
  .rt-thead .rt-td.-sort-desc .rt-resizable-header-content {
    &:after {
      content: "\2193";
      margin-left: 0.6em;
    }
  }

  .rt-thead .rt-th.-sort-asc .rt-resizable-header-content,
  .rt-thead .rt-td.-sort-asc .rt-resizable-header-content {
    &:after {
      content: '\2191';
      margin-left: 0.6em;
    }
  }

  .pagination-bottom {
    max-width: 1262px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @include breakpoint(small) {
      text-align: right;
    }
  }
}

.ReactTable.-compact {
  .rt-td,
  .rt-th {
    padding: 8px 16px;
  }
}
