@include bem-block(careers-navigation-block) {
  padding: 40px;
  margin: 0;

  @include bem-element(logo-text) {
    font-size: 1.4rem;
    font-weight: 700;
    opacity: 0.5;
  }

  @include bem-element(menu-item) {
    font-weight: bolder;
    padding: 16px;
    white-space: nowrap;
  }

  @include bem-element(sub-menu-item) {
    font-weight: normal;
    padding: 16px;
    white-space: nowrap;
  }

  @include bem-element(sub-nav-menu) {
    position: relative;
    padding: 16px 64px 16px 64px;
    display: block;
    background: white;
    color: black;

    &:hover {
      background-color: var(--theme-highlight-color);
      a {
        color: var(--theme-primary-font-color);
      }
    }
  }

  @include bem-element(hoverzone) {
    position: relative;

    &:hover {
      background-color: var(--theme-highlight-color);
    }
  }

  @include bem-element(menu-section, $modifier: center) {
    min-width: 0;
  }
}

@include bem-block(careers-navigation-block, $modifier: hero) {
}

@include bem-block(careers-navigation-block, $modifier: justified) {
  @include theme-font-color(primary);
  @include theme-background-color(primary);
}

@include bem-block(careers-navigation-block, $modifier: activation) {
  @include theme-font-color(primary);
  @include theme-background-color(primary);
}
