@include bem-block(selectable-item) {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  padding-bottom: 5px;

  &:hover {
    border-bottom: 2px solid $theme-color;
  }
}

@include bem-block(selectable-item, $modifier: active) {
  border-bottom: 2px solid $theme-color;
}

@include bem-block(selectable-item, $modifier: de-emphasised) {
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

@include bem-block(selectable-item, $modifier: disabled) {
  opacity: 0.6;
  cursor: not-allowed;
  transition: none;
  &:hover {
    opacity: 0.6;
    border-bottom: none;
  }
}