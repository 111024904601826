@include bem-block(flex-nav) {
  display: flex;
  flex-wrap: wrap;
  background: $theme-color-dark;

  @include bem-element(tab) {

  }

  @include bem-element(tab-link) {
    display: block;
    color: #FFF;
    padding: 0px 30px;
    line-height: $admin-spacing * 4;
    font-size: 1.4rem;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  @include bem-element(tab-link, $modifier: disabled) {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include bem-element(tab-link, $pseudo-class: hover) {
    background: $theme-color;
  }

  @include bem-element(tab-link, $modifier: active) {
    background: $theme-color;
  }
}
