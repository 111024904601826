@include bem-block(careers-card-block) {
  margin: 8px 0;

  @include breakpoint(small) {
    margin: 32px 0;
  }

  @include bem-element(content) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @include bem-element(icon) {
    height: 40px;
    margin-bottom: 20px;

    @include breakpoint(small) {
      height: 60px;
      margin-bottom: 30px;
    }
    @include breakpoint(medium) {
      height: 80px;
      margin-bottom: 40px;
    }
  }

  @include bem-element(image) {
    width: 100%;
    @include breakpoint(tiny) {
      max-width: 500px;
    }
    @include breakpoint(small) {
      max-width: 600px;
    }
  }

  @include bem-element(h3) {
    max-width: 100%; // ie11 fix
    color: brand-color("charcoal");
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.1;
    margin: 0 0 24px 0;
  }

  @include bem-element(p) {
    max-width: 100%; // ie11 fix
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 0 24px 0;
  }
}

@include bem-block(careers-card-block, $modifier: variant-large) {
  @include bem-element(h3) {
    font-size: 4.0rem;
    margin: 0 0 32px 0;
  }
  @include bem-element(p) {
    font-size: 1.8rem;
    margin: 0 0 40px 0;
  }
}


@include bem-block(careers-card-block, $modifier: align-left) {
  @include bem-elements(content) {
    align-items: flex-start;
  }
  @include bem-elements((h3, p)) {
    text-align: left;
  }
}


@include bem-block(careers-card-block, $modifier: align-center) {
  @include bem-elements(content) {
    align-items: center;
  }
  @include bem-elements((h3, p)) {
    text-align: center;
  }
  @include bem-elements((image)) {
    display: block;
    margin: 0 auto;
  }
}


@include bem-block(careers-card-block, $modifier: align-right) {
  @include bem-elements(content) {
    align-items: flex-end;
  }
  @include bem-elements((h3, p)) {
    text-align: right;
  }
}
