.SingleDatePicker_picker {
  z-index: 3; // To ensure that it sits on top of any Pretty checkboxes
}

.DayPicker_weekHeader_ul {
  line-height: 1; // Overriding the line height set on our 'elements' ul default
}

// Fudging the input to look like one of our own
// Needs to be done this way because there's a 'clear' icon that needs to look as if its within the input
.SingleDatePickerInput {
  @include default-input-styles;
}

.DateInput_input {
  @include default-input-styles;
  padding: 0;
  border: none;
}

.DateRangePickerInput {
  @include default-input-styles;
  line-height: 2;

  .DateInput {
    width: 90px;
  }

  .DateRangePickerInput_arrow {
    margin-right: 15px;
  }
}

.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput_clearDates_default:hover {
  background: none;
  border-radius: 0;
}

.DateRangePickerInput_clearDates_default:focus {
  outline: 0;
}
