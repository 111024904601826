@include bem-block(divider) {
  border: none;
  display: block;
  flex: 1;
  margin: 0;
  border-bottom: 1px solid $smoke;
}

@include bem-block(divider, $modifier: dark) {
  border-bottom: 1px solid $gainsboro;
}
