@mixin default-external-panel-text-styles {
  color: brand-color(xanadu-grey);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.6;
}

@mixin default-external-panel-subheading-styles {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.2;
}

@include bem-block(external-panel) {
  margin: 40px 0;

  @include breakpoint(small) {
    margin: 80px 0;
  }

  @include bem-element(header) {
    margin: 40px 0;

    @include breakpoint(medium) {
      margin: 60px 0;
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include bem-element(link) {
    @include theme-color(highlight);
    cursor: pointer;
    line-height: 1.6;
    text-decoration: underline;
  }

  @include bem-element(back-link) {
    color: brand-color("slate-grey");
    text-align: center;

    &:before {
      @include font-awesome-font-light;
      content: '\f104';
      font-size: 2em;
      margin-right: 10px;
      position: relative;
      top: 6px;
    }
  }

  @include bem-element(inner) {
    padding: 0 20px;

    @include breakpoint(small) {
      padding: 0 30px;
    }
  }

  @each $key in $theme-color-keys {
    @include bem-element(inner, $modifier: theme-#{$key}) {
      @include theme-background-color($key);
      padding: 20px;

      @include breakpoint(small) {
        padding: 30px;
      }
    }
  }

  @include bem-element(inner, $modifier: white) {
    background-color: white;
    padding: 20px;

    @include breakpoint(small) {
      padding: 30px;
    }
  }

  // NOTE: (TomLuce) Has to go after other inner colour modifiers
  @include bem-element(inner, $modifier: expansive) {
    padding: 60px 20px;

    @include breakpoint(small) {
      padding: 40px 60px;
    }

    @include breakpoint(medium) {
      padding: 80px 100px;
    }
  }

  @include bem-element(content) {
    padding-bottom: 40px;

    @include breakpoint(small) {
      padding-bottom: 80px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @include bem-element(content, $modifier: ruled) {
    border-bottom: 1px solid brand-color("silver");
    margin-bottom: 40px;

    @include breakpoint(small) {
      margin-bottom: 80px;
    }

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  @include bem-element(title) {
    color: brand-color("charcoal");
    font-size: 3.0rem;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
    text-align: center;

    @include breakpoint(small) {
      font-size: 4.0rem;
    }
  }

  @include bem-element(title, $modifier: large) {
    font-size: 4.0rem;

    @include breakpoint(small) {
      font-size: 6.0rem;
    }
  }

  @include bem-element(subtitle) {
    color: brand-color("charcoal");
    font-size: 2.0rem;
    line-height: 1.3;
    margin: 10px 0 0;

    @include breakpoint(small) {
      font-size: 2.4rem;
    }
  }

  @include bem-element(text) {
    @include default-external-panel-text-styles;

    font-size: 1.8rem;
  }

  @include bem-element(heading) {
    color: brand-color("charcoal");
    font-size: 2.0rem;
    font-weight: 500;
    margin: 2em 0 1.5em;

    @include breakpoint(small) {
      font-size: 2.4rem;
      margin: 2em 0 1.25em;
    }
  }

  @include bem-element(subheading) {
    @include default-external-panel-subheading-styles;
    @include theme-color(highlight);
    margin: 1em 0;
  }

  @include bem-element(trix-content) {
    @include default-external-panel-text-styles;

    a[href^="https://res.cloudinary.com"] {
      display: block;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 16px 0;
      display: block;
    }

    @media all and (-ms-high-contrast:none) {
      // Needed because IE thinks max-width: 100% is some sort of joke
      img, iframe {
        max-width: 755px;
      }
    }

    margin: 30px 0;

    a {
      @include theme-color(highlight);
    }

    strong {
      @include default-external-panel-subheading-styles;
    }

    ul,
    ol {
      li {
        line-height: 1.5;
        margin: 0.7em 0;
      }
    }

    ol {
      counter-reset: li;
      list-style-type: none;

      // Only target direct descendants when counting
      > li {
        counter-increment: li;

        &:before {
          content: counter(li) ".";
          display: inline-block;
          font-size: 1em;
          font-weight: normal;
          line-height: 1;
          margin-left: -1.5em;
          width: 1.5em;
        }
      }
    }
  }

  @include bem-element(trix-content, $modifier: lead-in) {
    font-size: 1.8rem;
    margin: 30px 0 60px;
  }

  @include bem-element(trix-content, $modifier: colored) {
    strong {
      @include theme-color(highlight);
    }

    ul li,
    ol li {
      &:before {
        @include theme-color(highlight);
      }
    }
  }
}

@each $key in $theme-color-keys {
  @include bem-block(external-panel, $modifier: theme-#{$key}) {
    @include theme-background-color($key);
  }
}
