@include bem-block(list) {
  padding-left: 20px;
  margin: 0;
  list-style: none;
  line-height: 1.9;
}

@include bem-block(list, $modifier: dashed) {
  padding-left: 0;
  li:before {
    content: '-';
    padding-right: 15px;
  }
}