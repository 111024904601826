@include bem-block(external-onboarding) {
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'header'
    'content'
    'footer';
}

@include bem-block(external-body) {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'header'
    'content'
    'footer';
}
