.rc-slider-handle {
  background-color: $theme-color;
  border-color: #FFF;
  margin-top: -7px;
  width: 20px;
  height: 20px;
}

.rc-slider-dot {
  width: 12px;
  height: 12px;
  bottom: -3px;
}

.rc-slider-step {
  height: 6px;
}

.rc-slider-rail {
  height: 6px;
}

.rc-slider-track {
  background-color: $theme-color;
  height: 6px;
}

.rc-slider-mark {
  font-size: 1.6rem;
  margin-top: 5px;
}