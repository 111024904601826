@include bem-block(checkable-input) {
  display: inline-block;
  position: relative;
  font-size: 1.6rem;

  @include bem-element(input) {
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 50;
    border: none;
    position: absolute;
    cursor: pointer;

    &[data-error-message] {
      + label {
        border-color: brand-color(brick-red) !important;
      }
    }

    &:focus {
      outline: #278749 solid 2px;
      border-color: transparent;
    }

    &:checked {
      + label {
        background: brand-color("cream");
        border-color: brand-color("cream");
        color: rgba(0, 0, 0, 0.5);

        &:before {
          @include font-awesome-font-solid;
          color: brand-color(brick-red);
          content: '\f058';
        }
      }
    }
  }

  @include bem-element(label) {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    padding: 1em 2em 1em 3em;
    position: relative;
    text-align: center;
    transition: 0.2s all ease;
    width: 100%;
    z-index: 51;

    &:before {
      @include font-awesome-font-light;
      content: '\f111';
      font-size: 1.25em;
      left: 0.7em;
      position: absolute;
      top: 0.65em;
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
    }
  }

  // Used in a button grouping
  @include bem-element(label, $modifier: borderless) {
    border: none;
  }
}

@include bem-block(checkable-input, $modifier: compact) {
  font-size: 1.2rem;

  @include bem-element(label) {
    padding: 0.35em 1.25em 0.35em 3em;

    &:before {
      left: 0.5em;
      top: 0.1em;
    }
  }
}

@include bem-block(checkable-input, $modifier: themed) {

  @include bem-element(input) {
    &:checked {
      + label {
        @include theme-background-color(secondary);
        @include theme-border-color(secondary);
        color: rgba(0, 0, 0, 0.5);

        &:before {
          @include theme-color(highlight);
        }
      }
    }
  }
}

@include bem-block(checkable-input, $modifier: full) {
  width: 100%;

  @include bem-element(label) {
    display: block;
  }
}

@include bem-block(checkable-input, $modifier: themed-secondary) {

  @include bem-element(input) {
    &:checked {
      + label {
        @include theme-background-color(highlight);
        @include theme-border-color(highlight);
        color: white;
      }
    }
  }

  @include bem-element(label) {
    padding: 20px 10px;
    &:before {
      display: none;
    }
  }
}
