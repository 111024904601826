@mixin default-input-styles {
  @include body-font;
  background-color: white;
  border: 1px solid $alto;
  border-radius: 0;
  color: $mortar;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.6;
  padding: $padding-input-vertical $padding-input-horizontal;
  width: 100%;
  -webkit-font-smoothing: subpixel-antialiased;

  &:focus {
    outline: none;
    border-color: $mortar;
  }
  &::placeholder,
  &::-webkit-datetime-edit {
    opacity: 0.5;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

