.hidden {
  display: none !important
}

// Hides an element until a specific screen size
@each $size in map-keys($breakpoint-sizes) {
  .hide-at-#{$size}-block {
    display: block;
    @media (min-width: map-get($breakpoint-sizes, $size)) {
      display: none !important;;
    }
  }
}

// Hides an element at a specific screen size
@each $size in map-keys($breakpoint-sizes) {
  .hide-#{$size}-inline {
    display: none;
    @media (min-width: map-get($breakpoint-sizes, $size)) {
      display: inline !important;
    }
  }
}

// Hides an element at a specific screen size
@each $size in map-keys($breakpoint-sizes) {
  .hide-#{$size}-block {
    display: none;
    @media (min-width: map-get($breakpoint-sizes, $size)) {
      display: block !important;
    }
  }
}

// Hides an element at a specific screen size (without specific display)
@each $size in map-keys($breakpoint-sizes) {
  .hide-#{$size}-table {
    display: none;
    @media (min-width: map-get($breakpoint-sizes, $size)) {
      display: table-cell;
    }
  }
}