@include bem-block(admin-sidebar) {
  display: none;
  padding: 0;
  background-color: brand-color(sage-500);
  height: 100%;
  min-width: $tt-grid-size * 9;
  z-index: 10;

  @include bem-element(nav) {
    height: 100%;
  }

  @include bem-element(wrapper) {
    height: 100%;

    .bp3-sidebar-nav {
      height: 100%;
    }
  }

  @include breakpoint(medium) {
    display: block;
    grid-area: sidebar;
  }

  .bp3-sidebar-nav-header {
    z-index: 10; // Its sticky and needs to stay on top of everything (except popovers and such, which are z-index 20)
    position: sticky;
    top: 0;
    background-color: brand-color(sage-500);
  }
}
