a {
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer;
    // Commenting this out because it interferes with anchor buttons
    //text-decoration: initial;
    //color: inherit;
  }
}
