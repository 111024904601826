@include bem-block(link) {
  position: relative;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s all ease;
  -webkit-font-smoothing: inherit;
  word-break: break-word;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@each $colorname, $color in $color-list {

  @include bem-block(link, $modifier: $colorname) {
    color: $color;
  }
  @include bem-block(link, $modifier: $colorname, $pseudo-class: hover) {
    @if $colorname == 'white' {
      color: $theme-color;
    } @else {
      color: darken($color, 15%);
    }
  }
}

@include bem-block(link, $modifier: theme-color) {
  color: $theme-color;

  &:hover {
    color: $theme-color-dark;
  }
}

@include bem-block(link, $modifier: theme-hover) {
  &:hover {
    color: $theme-color;
  }
}

@include bem-block(link, $modifier: bold) {
  font-weight: bold;
}

@include bem-block(link, $modifier: semi-bold) {
  font-weight: 500;
}

@include bem-block(link, $modifier: underline) {

  &:hover {
    text-decoration: underline;
  }
}

@each $colorname, $color in $brand-colors {

  @include bem-block(link, $modifier: brand-#{$colorname}) {
    color: $color;
  }
  @include bem-block(link, $modifier: brand-#{$colorname}, $pseudo-class: hover) {
    color: darken($color, 15%);
  }
}
