@include bem-block(button) {
  font-size: 1.6rem;
  max-width: 100%;
  padding: 0.7em 2em;
  font-weight: 500;
  line-height: 1.6;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  transition: 0.2s all ease;
  background-color: $theme-color;
  color: #fff;
  -webkit-font-smoothing: inherit;
  border: 1px solid $theme-color;

  // To deal with spacing icons in buttons
  @include bem-element(icon) {
    margin: 0 9px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
@include bem-block(button, $pseudo-class: hover) {
  background-color: darken($theme-color, 15%);
}
@include bem-block(button, $pseudo-class: focus) {
  outline: none;
}
@include bem-block(button, $pseudo-class: active) {
  outline: none;
}
@include bem-block(button, $pseudo-class: disabled) {
  opacity: 0.5;
  cursor: not-allowed;
}

// COLOURS

@each $colorname, $color in $color-list {

  // Standard Block colour buttons
  @include bem-block(button, $modifier: $colorname) {
    background-color: $color;
    border: 1px solid $color;
    @if $colorname == 'white' {
      color: $theme-color;
    } @else {
      color: #fff;
    }
  }
  @include bem-block(button, $modifier: $colorname, $pseudo-class: hover) {
    background-color: darken($color, 15%);

    &:hover {
      @if $colorname == 'white' {
        color: $theme-color;
      } @else {
        color: #fff;
      }
    }
  }

  // Inverted, as in the color is the outline and the text
  @include bem-block(button, $modifier: #{$colorname}-outline) {
    background-color: transparent;
    border: 1px solid $color;
    color: $color;
  }
  @include bem-block(button, $modifier: #{$colorname}-outline, $pseudo-class: hover) {
    color: darken($color, 15%);
    border-color: darken($color, 15%);
    background-color: transparent;
  }
}

@each $colorname, $color in $brand-colors {

  // Standard Block colour buttons
  @include bem-block(button, $modifier: brand-#{$colorname}) {
    background-color: $color;
    border: 1px solid $color;
    color: #fff;
  }
  @include bem-block(button, $modifier: brand-#{$colorname}, $pseudo-class: hover) {
    background-color: darken($color, 15%);
  }
}

@include bem-block(button, $modifier: flat) {
  background-color: transparent;
  border: 1px solid transparent;
  color: #FFFFFF;
}
@include bem-block(button, $modifier: flat, $pseudo-class: hover) {
  color:$theme-color;
  background-color: transparent;
  border-color: transparent;
}

@include bem-block(button, $modifier: opaque) {
  background-color: rgba($theme-color, 0.5);
  border: rgba($theme-color, 0.5);
  color: #FFFFFF;
}
@include bem-block(button, $modifier: opaque, $pseudo-class: hover) {
  background-color: rgba($theme-color, 0.7);
  border: rgba($theme-color, 0.7);
  color: #FFFFFF;
}

// OTHER MODIFIERS

@include bem-block(button, $modifier: full) {
  width: 100%;
}

@include bem-block(button, $modifier: compact) {
  padding: 0.35em 1.25em;
  min-width: unset;
  font-size: 1.3rem;
}

@include bem-block(button, $modifier: small) {
  padding: 0.4em 1.4em;
}

@include bem-block(button, $modifier: large) {
  padding: 1em 3em;
}

@include bem-block(button, $modifier: inline) {
  margin-right: 10px;
}
@include bem-block(button, $modifier: inline, $pseudo-class: last-child) {
  margin-right: 0;
}

@include bem-block(button, $modifier: link) {
  padding: 0;
  color: $theme-color;
  border: none;
  background-color: transparent;
  font-weight: inherit;
  line-height: 1.6;
  font-size: inherit;
  text-align: left;
}

@include bem-block(button, $modifier: link, $pseudo-class: hover) {
  color: $theme-color-dark;
  border: none;
  background-color: transparent;
}

@include bem-block(button, $modifier: sidebar-link) {
  padding: 0;
  color: $theme-color-dark;
  border: none;
  background-color: transparent;
  font-weight: bold;
  line-height: 1;
  font-size: inherit;
}
@include bem-block(button, $modifier: sidebar-link, $pseudo-class: hover) {
  color: $theme-color-dark;
  border: none;
  background-color: transparent;

  span {
    text-decoration: underline;
  }
}
@include bem-block(button, $modifier: admin-nav) {
  padding: 0;
  color: #fff;
  border: none;
  background-color: transparent;
  font-weight: 500;
  line-height: 1;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
@include bem-block(button, $modifier: admin-nav, $pseudo-class: hover) {
  color: darken(#fff, 20%);
  border: none;
  background-color: transparent;
}

@include bem-block(button, $modifier: selectable) {
  border: $theme-color;
  background-color: #fff;
  color: $theme-color;
  padding: 1em 1.5em;
  &:hover {
    background-color: $theme-color;
    color: #fff;
  }
}
