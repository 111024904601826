.plyr {

  // Increase the size of the triangle icon in the middle of the video that you click to play
  .plyr__control[data-plyr="play"]:not(.plyr__controls__item) {
    svg {
      transform: scale(1.6);
    }
  }

  // Change the colours of that same triangle
  .plyr__control--overlaid {
    background: white;
    color: brand-color("charcoal");
    padding: 40px;

    &:hover {
      background: brand-color("charcoal");
      color: white;
    }
  }
}
