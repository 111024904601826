@include bem-block(direct-upload) {
  background-color: $white-smoke;
  border: 1px solid $alto;
  padding: 10px 15px;
  width: 100%;
  font-size: 1.5rem;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: $border-radius;
  color: $theme-color;
  line-height: 1.6;
  position: relative;

  @include bem-element(progress) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    background: brand-color(sea-green);
    transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
    transform: translate3d(0, 0, 0);
  }
}

@include bem-block(direct-upload, $modifier: pending) {
  opacity: 0.6;
}

@include bem-block(direct-upload, $modifier: error) {
  color: map-get($alert-colors, 'error-color');
  background-color: map-get($alert-colors, 'error-background-color');
  border-color: map-get($alert-colors, 'error-border-color');
}

@include bem-block(direct-upload, $modifier: complete) {
  @include bem-element(progress) {
    opacity: 0.4;
  }
}

input[type=file][data-direct-upload-url][disabled] {
  display: none;
}
