@include bem-block(admin-card-list) {
  display: flex;
  flex-direction: column;

  @include bem-element(item) {
    border-bottom: 1px solid brand-color(ivory-300);
    padding: 8px 16px;
    transition: all 0.2s ease-in-out;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: brand-color(ivory-100);
    }
  }
}

@include bem-block(admin-card-list, $modifier: row) {
  flex-direction: row;

  @include bem-element(item) {
    border-bottom: none;
    border-right: 1px solid brand-color(ivory-300);

    &:last-child {
      border-right: none;
    }
  }
}
