@include bem-block(state-button) {
  @include body-font;
  padding: 1em 2em;
  font-size: 1.6rem;
  background: #fff;
  transition: all 0.2s ease-in;
  color: $grey;
  cursor: pointer;
  font-weight: 500;
  border: none;
  text-align: left;

  @include bem-element(icon) {
    transition: all 0.2s ease-in;
    color: $nobel;
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@include bem-block(state-button, $pseudo-class: focus) {
  outline: none;
}

@include bem-block(state-button, $modifier: active) {
  background: $nobel;
  color: #fff;
  border-color: $nobel;
  @include bem-element(icon) {
    color: #FFF;
  }
}

@include bem-block(state-button, $pseudo-class: hover) {
  @extend .state-button--active
}

@each $colorname, $color in $state-button-color-list {

  @include bem-block(state-button, $modifier: $colorname) {
    color: $color;
    @include bem-element(icon) {
      color: $color;
    }
  }

  @include bem-block(state-button, $modifier: #{$colorname}-active) {
    background: $color;
    color: #fff;
    border-color: $color;
    @include bem-element(icon) {
      color: #FFF;
    }
  }

  @include bem-block(state-button, $modifier: $colorname, $pseudo-class: hover) {
    @extend .state-button--#{$colorname}-active
  }
  @include bem-block(state-button, $modifier: #{$colorname}-active, $pseudo-class: hover) {
    @extend .state-button--#{$colorname}-active
  }

}

@each $colorname, $color in $state-button-color-list-light {

  @include bem-block(state-button, $modifier: #{$colorname}-sub-active) {
    background: $color;
    color: $silver;
    border-color: $color;
    @include bem-element(icon) {
      color: $silver;
    }
  }
}

@include bem-block(state-button, $modifier: full) {
  width: 100%;
}

@include bem-block(state-button, $modifier: compact) {
  padding: 0.5em 1em;
}

@include bem-block(state-button, $pseudo-class: disabled) {
  cursor: not-allowed;
  opacity: 0.5;
}