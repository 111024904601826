@include bem-block(code-editor) {
  counter-reset: line;
  overflow: visible !important;
  border: 1px solid #CCC;
  font-family: "Fira code", "Fira Mono", monospace;
  min-height: 200px;

  @include bem-element(line-number) {
    &:before {
      position: absolute;
      right: 100%;
      margin-right: 10px;
      text-align: right;
      opacity: .3;
      user-select: none;
      counter-increment: line;
      content: counter(line);
    }
  }

  // fixes for global comment class polluting the code editor styles
  .token.comment {
    width: inherit;
    margin-bottom: inherit;
    padding-left: 0;
    position: unset;
  }
}
