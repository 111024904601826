@include bem-block(admin-button) {
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 2.5px;
  padding: 1em 1.8em;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  position: relative;
}

@include bem-block(admin-button, $modifier: secondary) {
  background: transparent;
  border: 2px solid brand-color(apricot-500);
  color: brand-color(apricot-500);
  &.force-hover, &:hover {
    color: brand-color(apricot-600);
  }
}

@include bem-block(admin-button, $modifier: tertiary) {
  background: transparent;
  color: brand-color(grey-500);
  font-weight: 700;
  &.force-hover, &:hover {
    background: brand-color(ivory-200);
  }
}

@include bem-block(admin-button, $modifier: compact) {
  font-size: 1.1rem;
  letter-spacing: 2px;
  line-height: 1.45;
  padding: 0.6em 1.2em;
}

@include bem-block(admin-button, $modifier: full) {
  width: 100%;
}
