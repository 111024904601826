@include bem-block(event-calendar) {
  //min-height: 500px;
  position: relative;
  height: 1420px;

  @include bem-element(event-delete-button) {
    background-color: brand-color("dark-green");
    border-color: brand-color("dark-green");
    color: #FFF;
    cursor: pointer;
    width: auto;
    min-width: initial;
    position: absolute;
    font-size: inherit !important;
    font-weight: unset;
    padding: 0 4px 4px;
    font-family: inherit;
    right: 2px;
    top: 1px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    height: 1.85rem;
    line-height: 1.5rem;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: darken(brand-color("dark-green"), 10%);
    }
  }

  @include bem-element(title) {
    border-bottom: 1px solid #DDD;
    height: 40px;
    line-height: 40px;
  }

  @include bem-element(action) {
    padding: 8px 4px;
  }

  @include bem-element(toolbar-actions) {
    position: absolute;
    right: 0;
    top: 0;
  }
}
