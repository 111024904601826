@include bem-block(admin-header) {
  grid-area: header;
}

@include bem-block(admin-header) {
  z-index: inherit;
  position: static;
  top: inherit;

  @include breakpoint(medium) {
    z-index: 10; // Its sticky and needs to stay on top of everything (except popovers and such, which are z-index 20)
    position: sticky;
    top: 0;
  }
}

@include bem-block(admin-header) {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
  @include bem-element(desktop) {
    background-color: brand-color(ivory-100);
    border-bottom: 1px solid brand-color(ivory-300);
    color: brand-color(grey-500);
    display: none;
    height: ($tt-grid-size * 9);
    padding: 16px 30px;
    z-index: 10; // Its sticky and needs to stay on top of everything (except popovers and such, which are z-index 20)

    @include breakpoint(medium) {
      display: block;
    }
  }

  @include bem-element(desktop, $modifier: fullscreen) {
    display: block;
    height: auto;

    @include breakpoint(medium) {
      height: ($tt-grid-size * 9);
    }
  }

  @include bem-element(mobile) {
    align-items: center;
    background-color: brand-color(ivory-100);
    color: brand-color(grey-500);
    display: flex;
    height: 100%;
    padding: 20px 30px;

    @include breakpoint(medium) {
      display: none;
    }
  }
}
