@import '../../settings/variables';

$admin-main-grid-gap-small: $tt-grid-size * 2;
$admin-main-grid-gap-large: $tt-grid-size * 5;

@include bem-block(admin-main) {
  grid-area: main;
}

@include bem-block(admin-main) {
  background-color: brand-color(ivory-100);
  padding: $admin-main-grid-gap-small;

  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  grid-gap: $admin-main-grid-gap-small;

  @include breakpoint(medium) {
    padding: $admin-main-grid-gap-large;
    grid-gap: $admin-main-grid-gap-large;
  }

  @each $area in $admin-main-areas {
    @include bem-element($area) {
      grid-area: $area;
    }
  }
}

/**
  ----------------------------------------------------------------------------------------------------------------------
  Layouts to be used
  ----------------------------------------------------------------------------------------------------------------------
 */
@include bem-block(admin-main, $modifier: '1920-with-sidebar') {
  @include breakpoint(huge) {
    grid-template-columns: calc(1920px - #{$admin-main-grid-gap-large * 2} - #{$admin-sidebar-width});
  }
}

@include bem-block(admin-main, $modifier: '1920-without-sidebar') {
  @include breakpoint(huge) {
    grid-template-columns: calc(1920px - #{$admin-main-grid-gap-large * 2});
  }
}

@include bem-block(admin-main, $modifier: onboarding-dashboard) {
  justify-content: center;
  grid-template-areas:
    "left"
    "right";

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "left right";
  }
}

/**
  ----------------------------------------------------------------------------------------------------------------------
  DEPRECATED:
  Everything below this line is essentially deprecated unless moved into the section above
  ----------------------------------------------------------------------------------------------------------------------
 */

@include bem-block(admin-main, $modifier: '1120') {
  @include breakpoint(great) {
    grid-template-columns: 1120px;
  }
}

@include bem-block(admin-main, $modifier: simple-simon) {
  @include breakpoint(huge) {
    grid-template-columns: 1610px;
  }
}

@include bem-block(admin-main, $modifier: dingo-dave) {
  grid-template-areas:
    "large"
    "small";

  @include breakpoint(large) {
    grid-template-columns: 970px;
  }

  @include breakpoint(huge) {
    grid-template-columns: repeat(3, 515px);
    grid-template-areas: "large large small";
  }
}

@include bem-block(admin-main, $modifier: funky-fresh) {
  grid-template-areas:
    "overview"
    "small"
    "large";

  @include breakpoint(large) {
    grid-template-columns: 970px;
  }

  @include breakpoint(huge) {
    grid-template-columns: 455px repeat(2, 545px);
    grid-template-areas:
      "overview overview overview"
      "small large large";
  }
}

@include bem-block(admin-main, $modifier: gorgeous-gary) {
  grid-template-areas:
    "overview"
    "large"
    "small";

  @include breakpoint(large) {
    grid-template-columns: repeat(2, 470px);
    grid-template-areas:
      "overview overview"
      "large large"
      "small small";
  }

  @include breakpoint(huge) {
    grid-template-columns: 455px repeat(2, 545px);
    grid-template-areas:
      "overview overview overview"
      "large large small";
  }
}

@include bem-block(admin-main, $modifier: bingo-bill) {
  grid-template-areas:
    "overview"
    "large";

  @include breakpoint(huge) {
    grid-template-columns: 1610px;
  }
}
