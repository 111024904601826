/*
 * Essentially the same spinner animation that exists within Thumbtack.
 * This exists so we can display the same looking spinner in legacy Rails forms.
 */
@keyframes submit-button-spinner-animation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.submit-button-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
  margin-left: 8px;

  svg {
    display: block;
  }
  path {
    fill-opacity: 0;
  }
  .submit-button-spinner-head {
    transform-origin: center;
    transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    stroke: #455C51;
    stroke-linecap: round;
  }
  .submit-button-spinner-track {
    stroke: rgba(255,255,255,0.3);
  }
  .submit-button-spinner-animation {
    animation: pt-spinner-animation 500ms linear infinite;
  }
}