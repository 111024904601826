@include bem-block(react-dropdown) {
  position: relative;
  cursor: pointer;

  @include bem-element(click-handle) {
    width: 100%;
    //padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      position: relative;
      top: 2px;
    }
  }

  @include bem-element(click-handle, $modifier: active) {
    color: $theme-color;
    font-weight: 500;
  }

  @include bem-element(menu) {
    position: absolute;
    width: 200px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(85, 85, 85, 0.3);
    z-index: 2;
    transition: all 0.2s linear;
  }

  @include bem-element(menu-inner) {
    overflow-y: auto;
  }

  @include bem-element(menu, $pseudo-class: before) {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $smoke;
    position: absolute;
    top: -10px;
    z-index: 1;
    right: 20px;
  }

  @include bem-element(menu, $pseudo-class: after) {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -9px;
    z-index: 2;
    right: 20px;
  }

  @include bem-element(menu-exit) {
    opacity: 1;
  }

  @include bem-element(menu-exit-active) {
    opacity: 0;
  }

  @include bem-element(menu-enter) {
    opacity: 0;
  }

  @include bem-element(menu-enter-active) {
    opacity: 1;
  }

  @include bem-element(item) {
    padding: $admin-spacing;
    border-bottom: 1px solid $gainsboro;
    font-size: 1.6rem;
    color: $mortar;
    cursor: pointer;
    font-weight: normal;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include bem-element(item, $pseudo-class: hover) {
    background: $white-smoke;
  }

  @include bem-element(item, $pseudo-class: last-of-type) {
    border-bottom: none;
  }
}

@include bem-block(react-dropdown, $modifier: no-caret) {
  @include bem-element(menu, $pseudo-class: after) {
    border: none
  }

  @include bem-element(menu, $pseudo-class: before) {
    border: none
  }
}

@include bem-block(react-dropdown, $modifier: ruled) {
  @include bem-element(click-handle) {
    border-bottom: 1px dotted $mortar;
  }
}

@include bem-block(react-dropdown, $modifier: compact) {
  @include bem-element(item) {
    font-size: 1.4rem;
    padding: 10px;
  }
}
