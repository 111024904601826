@include bem-block(careers-block) {
  margin: 70px 0;

  @include breakpoint(small) {
    margin: 120px 0;
  }

  @include bem-element(h2) {
    color: brand-color("charcoal");
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.1;
    margin: 0 0 24px 0;
    text-align: center;

    @include breakpoint(small) {
      font-size: 6rem;
      margin: 0 0 40px 0;
    }
  }

  @include bem-element(h3) {
    color: brand-color("charcoal");
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.1;
    margin: 0 0 24px 0;
    text-align: center;

    @include breakpoint(small) {
      font-size: 3rem;
      margin: 0 0 40px 0;
    }
  }

  @include bem-elements((p, trix)) {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
    text-align: center;

    @include breakpoint(small) {
      font-size: 1.8rem;
    }
  }

  @include bem-element(p, $modifier: dark) {
    color: brand-color("charcoal");
  }

  @include bem-element(inner) {
    padding: 0 20px;

    @include breakpoint(small) {
      padding: 0 30px;
    }
  }

  @include bem-element(inner, $modifier: theme-secondary) {
    @include theme-background-color(secondary);
    padding-bottom: 40px;
    padding-top: 40px;

    @include breakpoint(small) {
      padding-bottom: 60px;
      padding-top: 60px;
    }
  }

  @include bem-element(link) {
    @include theme-color(highlight);
    cursor: pointer;
    line-height: 1.6;
    text-decoration: underline;
  }


  @include bem-element(content) {
    padding-bottom: 40px;

    @include breakpoint(small) {
      padding-bottom: 80px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

@include bem-block(careers-block, $modifier: theme-secondary) {
  @include theme-background-color(secondary);
  padding-bottom: 70px;
  padding-top: 70px;

  @include breakpoint(small) {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

@include bem-block(careers-block, $modifier: header) {
  margin-bottom: 0;
  padding-bottom: 70px;
  + .careers-block {
    margin-top: 0;
    padding-top: 0;
  }
}
