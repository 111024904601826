@include bem-block(external-button) {
  background-color: $theme-color;
  border: 1px solid $theme-color;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  max-width: 100%;
  padding: 1em 1.5em;
  text-align: center;
  transition: 0.2s all ease;

  &:focus,
  &:active {
    outline: none;
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @include breakpoint(tiny) {
    padding: 1em 3em;
    white-space: nowrap;
  }

  &:hover {
    // Darken the whole button rather than changing the background colour
    // Won't work on IE. @see https://css-tricks.com/almanac/properties/f/filter/
    color: white;
    filter: brightness(95%);
  }
}

@include bem-block(external-button, $modifier: full) {
  width: 100%;
}

@include bem-block(external-button, $modifier: compact) {
  padding: 0.5em 1.25em;
}

@each $key in $theme-color-keys {
  @include bem-block(external-button, $modifier: theme-#{$key}) {
    @include theme-background-color($key);
    @include theme-border-color($key);
    @include theme-font-color($key);

    &:hover {
      @include theme-font-color($key);
    }
  }

  @include bem-block(external-button, $modifier: theme-#{$key}-outline) {
    background-color: white;
    @include theme-border-color($key);
    @include theme-color($key);

    &:hover {
      @include theme-color($key);
    }
  }
}

@include bem-block(external-button, $modifier: linkedin) {
  background-color: #0073b1;
  border-color:  #0073b1;
  font-weight: 500;
  &:focus {
    outline: 1px solid black;
  }
}
