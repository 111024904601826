// deprecated: use text-start instead for RTL compatibility
.text-left {
  text-align: left !important;
}
.text-start {
  text-align: start !important;
}
// deprecated: use text-end instead for RTL compatibility
.text-right {
  text-align: right !important;
}
.text-end {
  text-align: end !important;
}
.text-center {
  text-align: center !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-italic {
  font-style: italic;
}
.text-normal {
  font-weight: normal;
  text-transform: none;
}
.text-bold {
  font-weight: bold;
}
.text-semi-bold {
  font-weight: 500;
}
.text-underline {
  text-decoration: underline;
}
.text-strikethrough {
  text-decoration: line-through;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text-nowrap {
  white-space: nowrap;
}
.text-wrapper {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.text-medium {
  font-size: 1.8rem;
}
.helper-text {
  font-size: 1rem;
  color: brand-color(ash-grey);
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.clickable {
  cursor: pointer;
}

@each $colorname, $color in $color-list {
  .text-#{$colorname} {
    color: $color !important;
  }
}
@each $colorname, $color in $brand-colors {
  .text-brand-#{$colorname} {
    color: $color !important;
  }
}
.text-theme-color {
  color: $theme-color;
}
.text-theme-color-dark {
  color: $theme-color-dark;
}

.text-highlight {
  background-color: map-get($alert-colors, info-background-color);
}

// Generate the various break widths.
$sizes-names: map-keys($breakpoint-sizes);

// Creates a break in the text at a certain size
// Usage: Start of text <span class='text-break-md'>text that will sit on a new line at medium and above</span>
@each $size in $sizes-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {
    .text-break-#{$size} {
      display: block;
    }
  }
}
