@include bem-block(careers-call-to-action-block) {
  @include bem-element(inner) {
    padding: 60px 20px;

    @include breakpoint(small) {
      padding: 60px 30px;
    }

    @include breakpoint(medium) {
      padding: 60px 40px;
    }

    // Prevent divs from wrapping
    @include breakpoint(medium) {
      .frow--row {
        flex-wrap: unset;
      }
    }
  }

  @include bem-element(line-separator-none) {
    display: none;
  }

  @include bem-element(line-separator-vertical) {
    @include breakpoint(medium) {
      display: block;
    }
  }

  @include bem-element(line-separator-horizontal) {
    display: block;
  }

  @include bem-element(trix) {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 40px;
  }

  @include bem-element(title-block) {
    gap: 20px;
  }

  @include bem-element(text-block-horizontal) {
    @include breakpoint(medium) {
      margin-left: 30px;
    }
  }
}

@include bem-block(careers-call-to-action-block, $modifier: text-align-left) {
  text-align: left;
}


