@include bem-block(toolbar) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  color: #FFFFFF;
  margin: -#{$admin-spacing};


  @include bem-element(title) {
    display: flex;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .02em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: $admin-spacing;
  }

  @include bem-element(menu) {
    display: flex;

    li {
      flex-basis: 100%;
      justify-content: center;

      a, button {
        width: 100%;
      }
    }

    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;

      li {
        flex-basis: auto;
        justify-content: flex-start;
      }
    }
  }

  @include bem-element(list) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 100%;
    list-style: none;
    padding: $admin-spacing * 0.5;
    margin: 0;
    font-weight: bold;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    li {
      display: flex;
      padding: $admin-spacing * 0.5;
    }

    a {
      font-weight: bold;
    }

    @include breakpoint(medium) {
      flex-basis: inherit;
      margin-left: $admin-spacing;
    }
  }
  @include bem-element(list, $modifier: end) {
    margin: 0;

    @include breakpoint(medium) {
      justify-content: flex-end;
      flex-basis: inherit;
      margin-left: 0;
      margin-right: $admin-spacing;
    }
  }

  @include bem-element(spacer) {
    flex-grow: 1!important;
  }
}

