@include bem-block(admin-section) {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  @include bem-element(header) {
    padding: 0 0 24px;
  }

  @include bem-element(icon-wrap) {
    display: none;
    font-size: 1em;
    margin-right: 16px;

    @include breakpoint(tiny) {
      display: inline-block;
    }
  }

  @include bem-element(icon-background) {
    color: brand-color(ivory-200);
  }

  @include bem-element(icon) {
    color: brand-color(grey-500);
  }

  @include bem-element(title) {
    color: brand-color(ebony-900);
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.33;
    margin: 0;
  }
}
