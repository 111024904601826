$brand-colors: (
  "charcoal": #1c2121,
  "dark-grey": #4A4A4A,
  "slate-grey": #616b6e,
  // This is the lightest grey that can be used against a white background to meet the WCAG AA requirements
  "xanadu-grey": #737873,
  "ash-grey": #8B8E8A,
  "silver": #bbc0be,
  "light-grey": #e7e9e6,
  "ivory": #f3ebd6,
  "cream": #fbf7ee,
  "light-peach": #f2c4a1,
  "dark-peach": #e3977e,
  "brick-red": #df514b,
  "mustard-yellow": #f0af4b,
  "sea-green": #2A8853,
  "dark-green": #435448,
  "apricot-100": #fae4de,
  "apricot-300": #f4c1b1,
  "apricot-500": #ed9d85,
  "apricot-600": #e98567,
  "blue-100": #cbe3f6, // Tom Luce color
  "blue-300": #4D9DE0, // Tom Luce color
  "blue-500": #2586d4, // Tom Luce color
  "ebony-600": #4b5e63,
  "ebony-700": #3c4c50,
  "ebony-800": #2e393c,
  "ebony-900": #1f2729,
  "green-100": #cdf1dd,
  "green-300": #36bc73,
  "green-500": #278753,
  "green-600": #237a4b,
  "grey-300": #bbb9b3,
  "grey-500": #969490,
  "grey-600": #85837f,
  "ivory-50": #fcfaf2,
  "ivory-100": #f7f3e7,
  "ivory-200": #efe9d7,
  "ivory-300": #e8dfca,
  "marine-100": #d2f0f4,
  "marine-300": #81d4df,
  "marine-500": #32b2c4,
  "marine-600": #2c9eac,
  "peach-300": #fbe0ce,
  "peach-500": #f6b98f,
  "peach-600": #f3a570,
  "purple-100": #e4d0f1, // Tom Luce color
  "purple-500": #A05ACC, // Tom Luce color
  "red-100": #fcdddc,
  "red-300": #f6928d,
  "red-500": #f1564e,
  "red-600": #ef382f,
  "sage-100": #e9f3ed,
  "sage-200": #cfe0d8,
  "sage-300": #85a495,
  "sage-400": #628373,
  "sage-500": #455c51,
  "sage-600": #364940,
  "white": #ffffff,
  "white-050": rgba(255, 255, 255, 0.5),
  "yellow-100": #feeac9,
  "yellow-300": #fdd086,
  "yellow-500": #fcb643,
  "yellow-600": #fba922,
);

@function brand-color($color-name) {
  @return map-get($brand-colors, $color-name);
}

$brand-font-colors: (
  "charcoal": #fff,
  "slate-grey": #fff,
  "ash-grey": brand-color("slate-grey"),
  "silver": brand-color("slate-grey"),
  "light-grey": brand-color("slate-grey"),
  "ivory": brand-color("dark-grey"),
  "cream": brand-color("dark-grey"),
  "light-peach": #f2c4a1,
  "dark-peach": #fff,
  "brick-red": #fff,
  "mustard-yellow": brand-color("dark-green"),
  "sea-green": #fff,
  "dark-green": #fff,
);

@function brand-font-color($color-name) {
  @return map-get($brand-font-colors, $color-name);
}
