/*
 * For better accessibility we need to wrap some elements in an interactive element.
 * E.g. an image in the gallery that when clicked brings up a lightbox
 * @see https://gist.github.com/MoOx/9137295
 */
@include bem-block(external-unstyled-a11y-button) {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Ensure focus styles only applied when keyboard is being used to navigate and not on button click */
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:focus:not(:-moz-focusring) {
    outline: none;
  }
}
