@include bem-block(sortable-item) {
  @include box-shadow();
  display: flex;
  border: 1px solid $smoke;
  margin-bottom: 10px;
  background-color: white;

  @include bem-element(header) {
    padding: 15px;
    background: $white-smoke;
    border-right: 1px solid $smoke;
  }

  @include bem-element(content) {
    flex-grow: 1;
    padding: 15px;
  }

  @include bem-element(footer) {
    padding: 15px;
    background: $white-smoke;
    border-left: 1px solid $smoke;
  }
}

@each $colorname, $color in $color-list {
  @include bem-block(sortable-item, $modifier: $colorname) {
    border-left: 5px solid $color;
  }
}

@include bem-block(sortable-item, $modifier: vertically-aligned) {
  align-items: stretch;

  @include bem-elements((header, content, footer)) {
    display: flex;
    align-items: center;
  }
}
