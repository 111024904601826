@include bem-block(external-definition-list) {
  dt {
    padding-bottom: 0.3em;
    &:after {
      content: ":";
    }
    @include theme-color(highlight);
    display: block;
    font-size: 1.4rem;
    font-weight: 700;
    margin: 15px 0 5px;
  }

  dd {
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.5);
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.45;
    margin: 0;
  }
}
