// TODO: ensure this file is distributed in thumbtack if we really want to import it
@import '~@pinpointhq/thumbtack/src/common/pinpoint-fonts';

@font-face {
  font-family: 'GTSectraDisplay';
  src: url('../../fonts/gt_sectra_display/GT-Sectra-Display-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/gt_sectra_display/GT-Sectra-Display-Regular.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTSectraDisplay';
  src: url('../../fonts/gt_sectra_display/GT-Sectra-Display-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/gt_sectra_display/GT-Sectra-Display-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GTSectraFine';
  src: url('../../fonts/gt_sectra_fine/GT-Sectra-Fine-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/gt_sectra_fine/GT-Sectra-Fine-Medium.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@mixin body-font {
  font-family: "GTWalsheimPro", "Helvetica Neue", "Helvetica", sans-serif;
}

@mixin header-font {
  font-family: "GTSectraDisplay", "Helvetica Neue", "Helvetica", sans-serif;
}

@mixin header-font-alt {
  font-family: "GTSectraFine", "Helvetica Neue", "Helvetica", sans-serif;
}

@mixin font-awesome-font {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
}

@mixin font-awesome-font-light {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
}

@mixin font-awesome-font-solid {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 700;
}
