@include bem-block(external-header) {
  @include theme-background-color(secondary);
  grid-area: header;
  padding: 16px 32px;

  @include breakpoint(large) {
    padding: 32px;
  }

  @include bem-element(text) {
    color: rgba(0, 0, 0, 0.5);
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
  }
}
