@include bem-block(admin-panel) {
  @include box-shadow;
  background-color: #FFF;
  margin-bottom: $admin-spacing;

  @include breakpoint(tiny) {
    margin-bottom: $admin-spacing * 2;
  }

  @include bem-element(inner) {
    padding: $admin-spacing;

    @include breakpoint(tiny) {
      padding: $admin-spacing * 2;
    }
  }

  @include bem-element(inner, $modifier: ruled) {
    border-bottom: 1px solid $gainsboro;
  }

  @include bem-element(header) {
    padding-bottom: $admin-spacing;
    margin-bottom: $admin-spacing;
    border-bottom: 1px solid $gainsboro;

    @include breakpoint(tiny) {
      padding-bottom: $admin-spacing * 2;
      margin-bottom: $admin-spacing * 2;
    }
  }

  @include bem-element(title) {
    font-size: 2.6rem;
    line-height: 1;
    margin: 0;
    font-weight: 500;
    letter-spacing: -0.8px;
  }

  @include bem-element(sub-title) {
    color: $theme-color;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    -webkit-font-smoothing: subpixel-antialiased;
    letter-spacing: 0.5px;
    line-height: 1;
    margin: 0.5em 0 0;
  }

  @include bem-element(secondary-title) {
    color: $theme-color;
    font-weight: 500;
    font-size: 2.3rem;
    margin: $admin-spacing * 3 0 $admin-spacing;
  }

  @include bem-element(tertiary-title) {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    margin: $admin-spacing * 3 0 $admin-spacing;
  }

  @include bem-element(content) {
    margin-bottom: $admin-spacing * 3;
    padding-bottom: $admin-spacing * 3;
    border-bottom: 1px solid $gainsboro;
    >:first-child {
      margin-top: 0;
    }
    >:last-child {
      margin-bottom: 0;
    }
  }

  @include bem-element(content, $pseudo-class: last-of-type) {
    margin: 0;
    border-bottom: none;
    padding: 0;
  }
}

@include bem-block(admin-panel, $pseudo-class: last-of-type) {
  margin-bottom: 0;
}
