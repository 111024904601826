@include bem-block(tile) {
  background-color: $theme-color;
  border: 1px solid;
  border-color: $theme-color;
  color: white;
  padding: $marketing-spacing;
  border-radius: $border-radius;

  @include bem-element(header) {
    color: white;
    font-size: 1.8rem;
    margin: 2rem 0 1rem;
  }

  @include bem-element(icon) {
    color: $theme-color-dark;
  }

  @include bem-element(text) {
    margin: 0;
  }

  @include breakpoint(medium) {
    min-height: auto;
    padding: $marketing-spacing * 2;
  }
}

@include bem-block(tile, $modifier: flat) {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  padding: 0;

  @include bem-element(header) {
    color: $theme-color-dark;
  }

  @include bem-element(icon) {
    color: $theme-color;
  }
}

@include bem-block(tile, $modifier: white-text) {

  @include bem-element(header) {
    color: white;
  }

  @include bem-element(text) {
    color: white;
  }
}

@include bem-block(tile, $modifier: white) {
  background-color: #FFFFFF;
  border-color: $smoke;

  @include bem-element(header) {
    color: $theme-color-dark;
  }

  @include bem-element(icon) {
    color: $theme-color;
  }
}



@include bem-block(tile, $modifier: large) {
  padding-top: 0;
  padding-bottom: 0;

  @include bem-element(header) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  @include bem-element(icon) {
    font-size: 2em;
  }
}

@include bem-block(tile, $modifier: panel) {
  border-color: $gainsboro;
  border-radius: 0;
  background-color: #FFFFFF;
}

@include bem-block(tile, $modifier: button) {
  @include box-shadow;
  outline: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
  padding: $marketing-spacing;
  height: 100%;

  &:hover {
    box-shadow:inset 0 0 0 99999px rgba(0, 0, 0, 0.05);
    background-color: $theme-color;
    border-color: $theme-color;
    color: #fff;

    @include bem-element(icon) {
      color: #fff;
    }

    @include bem-element(header) {
      color: #fff;
    }
  }

  @include breakpoint(medium) {
    padding: $marketing-spacing * 2;
  }
}

@include bem-block(tile, $modifier: dark) {
  background-color: $theme-color-dark;
  border-color: $theme-color-dark;

  @include bem-element(icon) {
    color: $theme-color;
  }

  @include bem-element(header) {
    color: #FFFFFF;
  }

  @include bem-element(text) {
    color: #FFFFFF;
  }

  &:hover {
    background-color: $theme-color-dark;

    @include bem-element(icon) {
      color: $theme-color;
    }
  }
}

@include bem-block(tile, $modifier: fixed-height) {
  height: 100%;

  @include breakpoint(small) {
    height: 150px;
  }

  @include breakpoint(medium) {
    padding: $marketing-spacing;
  }

  @include breakpoint(large) {
    padding: $marketing-spacing * 2;
  }
}

@include bem-block(tile, $modifier: impact) {
  text-align: center;

  @include bem-element(header) {
    color: $theme-color-dark;
    font-size: 4rem;
  }

  @include bem-element(text) {
    color: initial;
  }
}
