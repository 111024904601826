@include bem-block(form) {

  @include bem-element(fieldset) {
    border: none;
    margin: 0 0 $admin-spacing * 3;
    border-bottom: 1px solid $smoke;
    padding: 0 0 $admin-spacing * 3;
    position: relative;
  }

  @include bem-element(fieldset, $pseudo-class: last-of-type) {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @include bem-element(legend) {
    font-weight: 500;
    font-size: 2.3rem;
    color: $theme-color;

    p {
      color: $mortar;
      line-height: 1.6;
      font-size: 1.6rem;
      margin: 0.5em 0;
      font-weight: normal;
      &:first-child {
        margin-top: 0.3em;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include bem-element(row) {
    margin-bottom: $admin-spacing;
    position: relative;
    width: 100%;
  }

  @include bem-element(row, $pseudo-class: last-of-type) {
    margin-bottom: 0;
  }

  @include bem-element(label) {
    @include default-label-styles;
    margin-bottom: 0.8em;
    display: inline-block;
    line-height: 1.5;
    text-transform: uppercase;

    span {
      display: block;
      font-weight: normal;
      text-transform: initial;
      color: $grey;
      word-break: break-word; // To stop really long URLs and such from making the parent too wide
    }
  }

  @include bem-element(label, $modifier: alt) {
    margin-bottom: 0.6em;
    color: $mortar;
    font-size: 1.5rem;
    span {
      display: inline;
      text-transform: uppercase;
    }
  }

  @include bem-element(submit) {
    margin-top: $admin-spacing * 2;
    text-align: right;
  }

  @include bem-element(header) {
    font-size: 3rem;
    font-weight: 500;
    margin-top: 0;
    color: $theme-color-dark;
  }

  @include bem-element(link) {
    font-size: 3rem;
    font-weight: bold;
    margin-top: 0;
    color: $theme-color-dark;
  }

  @include bem-element(error-message) {
    color: brand-color(brick-red);
    font-size: 1.4rem;
    line-height: 1.3;
    margin: 0.5em 0 1em;
  }
}
