@include bem-block(progress-bar) {
  background-color: transparent;
  display: block;
  position: relative;
  height: 16px;
  overflow: hidden;

  @include bem-element(progress) {
    height: 16px;
  }
}

@include bem-block(progress-bar, $modifier: condensed) {
  height: 8px;

  @include bem-element(progress) {
    height: 8px;
  }
}

$progress-bar-colors: (
  'sage-500',
  'green-500',
  'blue-500',
  'red-500',
  'yellow-500',
  'ivory-300'
);

@each $colorname in $progress-bar-colors {

  @include bem-block(progress-bar, $modifier: $colorname) {
    @include bem-element(progress) {
      background-color: brand-color($colorname);
    }
  }
}
