
.rc-time-picker {

  &-panel {
    width: 200px;

    &-inner {
      margin-top: 3px;
      width: inherit
    }

    &-narrow {
      max-width: 200px;
    }

    &-select {
      width: 99.5px;
      max-height: 200px;
      li {
        font-size: 1.6rem;
        height: 3.2rem;
        line-height: 2;
      }
    }

    &-input {
      background-color: white;
      border-width: 0;
      border-bottom: 1px solid #e9e9e9;
      line-height: 1.6;
      padding: 10px 15px;

      &-wrap {
        padding: 0;
      }
    }
  }
}
