@include bem-block(mobile-actions-bar) {
  background: white;
  border-top: 1px solid brand-color("light-grey");
  bottom: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  left: 0;
  opacity: 0;
  padding: 10px;
  position: fixed;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: -1;
  display: none;
}

@include bem-block(mobile-actions-bar, $modifier: visible) {
  opacity: 1;
  z-index: 2;
  display: block;
}
