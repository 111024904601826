@include bem-block(external-check-circle) {
  @include theme-background-color(highlight);
  align-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 8rem;
  justify-content: center;
  margin: 0 auto;
  width: 8rem;

  &:after {
    @include font-awesome-font-light;
    content: '\f00c';
    color: white;
    font-size: 4rem;
  }
}
