input {
  @include default-input-styles;
  &[data-error-message] {
    border-color: brand-color(brick-red);
  }

  // Something about the line-height in inputs IE doesn't like. This should target IE 9 and above (apprently)
  // https://stackoverflow.com/questions/28417056/how-to-target-only-ie-any-version-within-a-stylesheet
  @media screen and (min-width:0\0) and (min-resolution: +72dpi) {
    line-height: normal;
  }
}

:-ms-input-placeholder {
  opacity: 0.5 !important;
}