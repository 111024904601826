@include bem-block(facebook-preview) {
  margin: 0 auto;
  max-width: 320px;
  border: 1px solid #dddfe2;
  border-radius: 3px;

  @include bem-element(header) {
    padding: 10px 10px 0;
  }

  @include bem-element(title) {
    font-size: 1.6rem;
    color: #1d2129;
  }

  @include bem-element(subtitle) {
    color: #90949c;
    font-size: 1.4rem;
  }

  @include bem-element(web-address) {
    color: #464950;
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
  }

  @include bem-element(text) {
    //min-height: 25px;
  }

  @include bem-element(content) {
    margin-top: -4px;
    background: #e8eaee;
    padding: 18px 12px;
  }

  @include bem-element(image) {
    width: 100%;
    height: auto;
  }

  @include bem-element(button) {
    font-size: 1.4rem;
    color: #1c2028;
    border: 1px solid #1c2028;
    box-shadow: none;
    border-radius: 5px;
    background: #e8eaee;
  }
}