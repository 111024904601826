@include bem-block(tabbed-nav) {
  display: flex;
  flex-wrap: wrap; // React needs this to work out how large the container is
  background-color: $white-smoke;

  @include bem-element(tab) {
    text-align: left;
    color: $suva;
    padding: 15px 20px;
    background: $white-smoke;
    font-weight: 500;
    letter-spacing: -0.5px;
    transition: 0.2s all ease;
    display: block; // Important for the dropdown on smaller screen

    @include breakpoint(medium) {
      padding: 20px 30px;
    }
  }

  @include bem-element(tab, $modifier: inline) {
    display: flex;
    align-items: center;
  }

  @include bem-element(tab-highlight) {
    display: block;
    font-size: 1.3em;
    margin-bottom: 5px;
  }

  @include bem-element(tab-highlight, $modifier: inline) {
    width: 30px;
    margin-bottom: 0;
  }

  @include bem-element(tab, $modifier: active) {
    background: #FFF;
  }

  @include bem-element(tab, $pseudo-class: hover) {
    background: #FFF;
  }

  // override for onboarding portal TODO (DomSamphier): change
  @include bem-element(onboarding-tab) {
    text-align: left;
    color: $suva;
    padding: 15px 20px;
    background: $white-smoke;
    font-weight: 500;
    letter-spacing: -0.5px;
    transition: 0.2s all ease;
    display: block;

    @include breakpoint(medium) {
      padding: 20px 30px;
    }
  }

  @include bem-element(onboarding-tab, $modifier: active) {
    background: #FFF;
  }

  @include bem-element(onboarding-tab, $pseudo-class: hover) {
    cursor: not-allowed;
  }
}
