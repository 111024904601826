// The modal uses em units, so we need to set the font size properly
.swal2-popup,
.swal2-modal {
  font-size: 1.6rem !important;
}
.swal2-modal {
  button {
    border-radius: 0 !important;
  }
}

.swal2-popup .swal2-title {
  font-weight: 500;
}

.swal2-confirm {
  background: brand-color(sea-green) !important;
}

// The textarea also uses em's, need to be changed so they don't look stupidly big
.swal2-popup .swal2-textarea {
  font-size: 1.6rem;
}

// To stop the footer from being pushed upwards when we have a sweet alert 2 modal
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  height: 100%;
}

// Fix for the success tick and animation
.swal2-icon,
.swal2-icon.swal2-success,
.swal2-icon.swal2-question,
.swal2-icon.swal2-warning,
.swal2-icon.swal2-info,
.swal2-success-ring {
  box-sizing: content-box !important;
}
