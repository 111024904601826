@include bem-block(search) {
  position: relative;

  @include bem-element(input) {
    padding-left: 0.8em + 1.2em + 0.6em;
    padding-right: 0.8em + 1.2em + 0.6em;
  }

  /*
    These are a quick fix to get rid of FontAwesome from the front end.
    Ideally this search button should be coded in Thumbtack.
  */
  > .bp3-icon-custom {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  > .bp3-spinner {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  > .bp3-icon-button {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
