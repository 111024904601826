@include bem-block(brand-pattern) {
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  svg {
    height: 48px;
    width: 48px;
  }
}

@include bem-block(brand-pattern, $modifier: responsive) {
  width: 100%;
  height: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
}

@for $multiple from 1 through 16 {
  @include bem-block(brand-pattern, $modifier: '#{$multiple}x') {
    svg {
      height: #{(8 * $multiple)}px;
      width: #{(8 * $multiple)}px;
    }
  }
}

// Enables us to switch sizes between breakpoints
@each $size in map-keys($breakpoint-sizes) {

  @for $multiple from 1 through 16 {
    @include bem-block(brand-pattern, $modifier: '#{$size}-#{$multiple}x') {

      @media (min-width: map-get($breakpoint-sizes, $size)) {
        svg {
          height: #{(8 * $multiple)}px;
          width: #{(8 * $multiple)}px;
        }
      }
    }
  }
}
