/**
  NOTE: (TomLuce) I believe this is only used in one place so can probably be removed at some point
 */
@each $size in $breakpoint-size-names {
  @media (min-width: map-get($breakpoint-sizes, $size)) {
    @include bem-block(content-wrap-#{$size}) {
      width: map-get($breakpoint-sizes, $size);
    }
    @include bem-block(content-wrap-#{$size}, $modifier: 'center') {
      margin: 0 auto;
    }
  }
}
