@include bem-block(gravatar) {
  display: inline-block;
  height: 32px;
  position: relative;
  width: 32px;

  @include bem-element(fallback) {
    background-color: brand-color(green-500);
    border-radius: 50%;
    font-size: 1.3rem;
    font-weight: 700;
    height: 32px;
    position: absolute;
    text-transform: uppercase;
    width: 32px;
    z-index: 1;
    &:after {
      color: brand-color("white");
      content: attr(data-initials);
      display: inline-block;
      line-height: 32px;
      text-align: center;
      width: 32px;
    }
  }

  @include bem-element(image) {
    border-radius: 50%;
    height: 32px;
    position: absolute;
    width: 32px;
    z-index: 2;
  }
}
