@include bem-block(date-select) {
  border: 1px solid $alto;
  display: inline-flex;
  padding: 0.7em 1em;

  @include bem-element(field) {
    border: none;
    border-right: 1px solid $alto;
    padding: 0 1em;
    width: auto;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: none;
      padding-right: 0;
    }
  }
}
