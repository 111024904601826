// Stolen from here https://codepen.io/fusco/pen/XbpaYv and edited slightly
.typing-indicator {
  will-change: transform;
  width: auto;
  padding: 7.5px;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;
  span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #FFFFFF;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}