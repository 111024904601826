@include bem-block(admin-card) {
  background-color: brand-color("white");
  border: 1px solid brand-color(ivory-200);
  box-shadow: 1vw 1vw brand-color(ivory-200);
  color: brand-color(grey-500);
  font-size: 1.5rem;
  line-height: 1.6;
  width: calc(100% - 1vw);

  @include bem-element(inner) {
    padding: 0 24px;
    margin: 16px 0;
    &:last-child {
      margin-bottom: 24px;
    }
  }

  @include bem-element(backdrop) {
    background: brand-color(ivory-50);
    color: brand-color(ebony-900);
    padding: 16px 16px;
  }

  @include bem-element(backdrop, $modifier: sage-100) {
    background: brand-color(sage-100);
  }

  @include bem-element(backdrop, $modifier: apricot-500) {
    background: brand-color(apricot-500);
    color: brand-color("white");
  }

  @include breakpoint(small) {
    box-shadow: 4px 4px brand-color(ivory-200);
    width: calc(100% - 4px);
  }

  @include bem-element(header) {
    padding: 24px 24px 0;
    margin-bottom: 16px;
  }

  @include bem-element(headline) {
    color: brand-color(ebony-900);
    font-size: 1.3rem;
    font-weight: 700;
    letter-spacing: 2.5px;
    line-height: 1.23;
    margin: 0;
    text-transform: uppercase;
  }

  @include bem-element(title) {
    color: brand-color(ebony-900);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.33;
    margin: 24px 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  @include bem-element(subtitle) {
    color: brand-color(grey-500);
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.6;
    margin: 0.3em 0 0;
  }

  @include bem-element(helper-text) {
    color: brand-color(grey-500);
    font-size: 1.3rem;
    line-height: 1.23;
  }

  @include bem-element(content) {
    border-bottom: 1px solid brand-color(ivory-200);
    flex-grow: 1;
    margin-bottom: 8px;

    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  @include bem-element(footer) {
    background: brand-color(ivory-50);
    padding: 16px 24px;
  }
}

@include bem-block(admin-card, $modifier: flat) {
  box-shadow: none;
  width: 100%;
}
